import {CardElement, Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import React, {useMemo} from "react";
import {useFacade} from "./paymentFormComponent.hooks";
import './PaymentFormComponent.scss';
import {PaymentFormDescriptionComponent} from "../paymentFormDescriptionComponent/PaymentFormDescriptionComponent";
import {Box} from "@material-ui/core";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#000',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

interface PaymentFormComponentProps extends PaymentPageProps {
    publicKey: string;
}

export const PaymentFormComponent: React.FC<PaymentFormComponentProps> = (props: PaymentFormComponentProps) => {
    const {
        error,
        publicKey,
        setPaymentToken
    } = props;

    const stripePromise = useMemo(() => loadStripe(publicKey), [publicKey]);

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm
                error={error}
                setPaymentToken={setPaymentToken}
            />
        </Elements>
    )
}

interface PaymentPageProps {
    setPaymentToken: Function;
    error: string;
}

const PaymentForm: React.FC<PaymentPageProps> = (props: PaymentPageProps) => {
    const {
        error: tokenError,
        setPaymentToken
    } = props;

    const [
        {
            error
        },
        handleChange,
    ] = useFacade(setPaymentToken);

    return (
        <div className="form-row">
            <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => handleChange(event)}
            />
            <div className="card-errors" role="alert">{error ?? tokenError}</div>
            <Box pt={2}>
                <PaymentFormDescriptionComponent />
            </Box>
        </div>
    );
}
