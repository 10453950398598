import {Query} from '@datorama/akita';
import {PermissionType, UserType} from '../../models/auth.enums';
import {AuthState, AuthStore, authStore} from './auth.store';
import moment from "moment";

/**
 * Provides method for working with auth store
 */
export class AuthQuery extends Query<AuthState> {
    constructor(protected store: AuthStore) {
        super(store);
    }

    public getId(): number {
        return this.getValue().id;
    }

    /**
     * Returns auth type
     */
    public getUserType(): UserType {
        return this.getValue().userType;
    }

    /**
     * Returns user permissions
     */
    public getPermissions(): PermissionType[] {
        return this.getValue().permissions;
    }

    /**
     * Returns is user logged in
     */
    public isLoggedIn(): boolean {
        const token = this.getValue().token;
        if (!token || !token.length) {
            return false;
        }

        const now = moment();
        const expires = moment.unix(this.getValue().expires);
        return now < expires;
    }

    /**
     * Returns user token
     */
    public getToken(): string {
        return this.getValue().token;
    }

    public hasPermission(permission: PermissionType): boolean {
        return this.getPermissions().includes(permission);
    }
}

export const authQuery = new AuthQuery(authStore);
