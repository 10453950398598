import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodResult } from '@stripe/stripe-js';

interface PaymentFormState {
    error: string;
}

export function useFacade(setPaymentToken: Function): [
        PaymentFormState, 
        Function,
    ] {

    const [state, setState] = useState({
        error: null
    } as PaymentFormState);

    const stripe = useStripe();
    const elements = useElements();

    const getPaymentToken = async () : Promise<PaymentMethodResult> => {
        const card = elements.getElement(CardElement);
        return await stripe.createPaymentMethod({ type: "card", card: card });
    }

    const handleChange = async (e) => {
        if (e.empty) {
            elements.getElement(CardElement).clear();

            setState({
                ...state,
                error: null
            });

            return;
        }

        const paymentMethod = await getPaymentToken();

        setState({
            ...state, 
            error: paymentMethod.error ? paymentMethod.error.message : null
        });

        setPaymentToken(paymentMethod.error ? '' : paymentMethod.paymentMethod.id);
    }

    return [
        state, 
        handleChange,
    ];
}
