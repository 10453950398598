import { Box } from "@material-ui/core";
import React from 'react';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { Pages } from "../../../../constants/pages.constants";
import { Titles } from "../../../../constants/title.constants";
import { MenuItemType } from "../../../../models/menu.models";
import { ManageDeniedLicensesComponent } from "../../components/manageDeniedLicensesComponent/ManageDeniedLicensesComponent";

export const DeniedLicensesPage: React.FC = () => {
    return (
        <LayoutComponent title={Titles.deniedLicenses} selectedMenuItem={MenuItemType.DeniedLicenses} pageName={<span>{Pages.deniedLicenses}</span>}>
            <Box mt={5} p={5}>
                <ManageDeniedLicensesComponent />
            </Box>
        </LayoutComponent>
    )
}