import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { LicenseAccessStatus, LicenseModel } from "../../models/licenses.models";
import { licensesService } from "../../services/licenses.service";
import { licensesQuery } from "../../stores/licenses";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import { confirmService } from "../../../../services/confirm.service";

export enum SortingSource {
    Name = "licenseeName",
    submissionDate = "submissionDate",
    BusinessName = "businessName",
    IsFellow = "isFellow",
}

const columns = [
    {
        title: 'Name',
        source: SortingSource.Name,
        direction: SortingDirection.Asc
    },
    {
        title: 'Business Name',
        source: SortingSource.BusinessName,
        direction: SortingDirection.Asc
    },
    {
        title: 'Date Of Submission',
        source: SortingSource.submissionDate,
        direction: SortingDirection.Asc
    },
    {
        title: 'Fellow',
        source: SortingSource.IsFellow,
        direction: SortingDirection.Asc
    }
];

export interface LicenseViewModel {
    id: number;
    licenseeName: string;
    accessStatus: LicenseAccessStatus;
    submissionDate: Date;
    businessName: string;
    isFellow: boolean;
}

export interface ManagePendingLicensesComponentState extends ISortingState {
    isLoading: boolean;
    isActionsOpen: boolean;
    isInProgress: boolean;
    isConfirmDenyOpened: boolean;
    targetLicenseId: number;
    pendingLicenses: LicenseViewModel[];
    actionsAnchorEl: HTMLButtonElement;
}

export function useFacade(): [
    ManagePendingLicensesComponentState,
    (source: string, direction: SortingDirection) => void,
    (licenseId: number) => void,
    (value: boolean, licenseId: number, target: HTMLButtonElement) => void,
    () => void,
    () => void,
    (reason: string) => void,
] {
    const history = useHistory();

    const [state, setState] = useState({
        isLoading: true,
        isActionsOpen: false,
        isInProgress: false,
        isConfirmDenyOpened: false,
        targetLicenseId: 0,
        pendingLicenses: [],
        actionsAnchorEl: null,
        sortingColumns: columns,
        sortingSource: SortingSource.Name
    } as ManagePendingLicensesComponentState);

    const mapLicenses = (licenses: LicenseModel[]) => {
        return licenses.map(i => {
            return {
                id: i.id,
                licenseeName: i.licenseeName,
                accessStatus: i.accessStatus,
                submissionDate: i.createdAt,
                businessName: i.business?.name,
                isFellow: i.business?.provider?.isFellowship ?? false,
            };
        });
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({
            ...state,
            columns: state.sortingColumns
        }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            businesses: state.pendingLicenses.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleReviewLicense = (licenseId: number): void => {
        navigationService.toApproveLicense(history, licenseId);
    }

    const handleToggleLicenseActions = (value: boolean, licenseId: number, target: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            isActionsOpen: value,
            targetLicenseId: licenseId,
            actionsAnchorEl: target
        }))
    }

    const handleToggleDenyLicense = () => {
        setState(state => ({...state, isConfirmDenyOpened: !state.isConfirmDenyOpened, isActionsOpen: false}));
    }

    const removeLicenseFromList = (licenseId: number) => {
        setState(state => ({...state, pendingLicenses: state.pendingLicenses.filter(i => i.id !== licenseId)}))
    };

    const handleApproveLicense = () => {
        setState(state => ({...state, isActionsOpen: false}));

        const cb = () => removeLicenseFromList(state.targetLicenseId);

        confirmService.confirm(`Are you sure you want to approve this application?`).subscribe(
            () => licensesService.Approve({id: state.targetLicenseId}).subscribe(() => cb())
        );
    }

    const handleDenyLicense = (reason: string) => {
        setState(state => ({...state, isConfirmDenyOpened: false}));

        const cb = () => removeLicenseFromList(state.targetLicenseId);

        licensesService.Deny({id: state.targetLicenseId, reason: reason}).subscribe(() => cb());
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<LicenseModel[]>(licensesQuery.pendingLicenses$, licenses => {
                setState(state => ({...state, pendingLicenses: mapLicenses(licenses)}));
            }),
        ];

        licensesService.getPending().subscribe(licenses => setState(state => ({...state, pendingLicenses: mapLicenses(licenses), isLoading: false})));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [
        state,
        handleSorting,
        handleReviewLicense,
        handleToggleLicenseActions,
        handleToggleDenyLicense,
        handleApproveLicense,
        handleDenyLicense,
    ];
}
