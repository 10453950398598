import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { snackService } from '../../common/snack/state';
import { AgreementModel } from "../models/agreements.models";
import { agreementsStore, AgreementsStore } from "../stores/agreements";

/**
 * Provides method for working with agreements
 */
export class AgreementsService {
    private url = `${process.env.REACT_APP_API_URL}Agreements/`;

    constructor(private agreementsStore: AgreementsStore) {
    }

    public getActual(): Observable<AgreementModel[]> {
        return new Observable(observer => {
            Axios.get<AgreementModel[]>(`${this.url}Actual`)
                .pipe()
                .subscribe(
                    (response) => {
                        this.agreementsStore.update({agreements: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const agreementsService = new AgreementsService(agreementsStore);
