import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { BusinessModel } from "../../models/business.model";
import { businessesService } from "../../services/businesses.service";
import { businessesQuery } from "../../stores/businesses";
import {navigationService} from "../../../../services/navigation.service";

export enum SortingSource {
    None,
    Name = "name",
    Licensee = "licenseeName",
    IsActive = "isActive",
}

const sortingColumns = [
    {
        title: 'Name',
        source: SortingSource.Name,
        direction: SortingDirection.Asc
    },
    {
        title: 'Licensee',
        source: SortingSource.Licensee,
        direction: SortingDirection.Asc
    },
    {
        title: 'Active License',
    },
];

interface BusinessViewModel {
    id: number;
    practiceId: number;
    name: string;
    licenseeName: string;
    isActive: boolean;
}

export interface ManageBusinessesComponentState extends ISortingState {
    isLoading: boolean;
    businesses: BusinessViewModel[];
}

export function useFacade(): [
    ManageBusinessesComponentState,
    (source: string, direction: SortingDirection) => void,
    (business: BusinessViewModel) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        businesses: [],
        sortingColumns: sortingColumns,
        sortingSource: SortingSource.Name
    } as ManageBusinessesComponentState);

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({
            ...state,
            columns: state.sortingColumns
        }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            businesses: state.businesses.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleLoginToBusiness = (business: BusinessViewModel) => {
        navigationService.toLoginOnBehalf(business.id, business.practiceId);
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<BusinessModel[]>(businessesQuery.businesses$, businesses => {
                const items = businesses.map(i => { return {
                    id: i.id,
                    name: i.name,
                    licenseeName: i.licenseeName,
                    practiceId: i.practice.id,
                    isActive: i.isActive
                } });

                setState(state => ({
                    ...state,
                    businesses: items
                }))
            })
        ];

        const businessesGetCB = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }

        businessesService.get().subscribe(businessesGetCB, businessesGetCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [state, handleSorting, handleLoginToBusiness];
}
