import { Box } from "@material-ui/core";
import React from 'react';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { Pages } from "../../../../constants/pages.constants";
import { Titles } from "../../../../constants/title.constants";
import { MenuItemType } from "../../../../models/menu.models";
import { ManageActiveLicensesComponent } from "../../components/manageActiveLicensesComponent/ManageActiveLicensesComponent";

export const ActiveLicensesPage: React.FC = () => {
    return (
        <LayoutComponent title={Titles.activeLicenses} selectedMenuItem={MenuItemType.ActiveLicenses} pageName={<span>{Pages.activeLicenses}</span>}>
            <Box mt={5} p={5}>
                <ManageActiveLicensesComponent />
            </Box>
        </LayoutComponent>
    )
}