import {Box, IconButton, Popover, TableSortLabel} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import {useHistory} from "react-router";
import {MenuItemType} from "../../../../models/menu.models";
import {navigationService} from "../../../../services/navigation.service";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import {WildHealthLinkButton} from "../../../common/components/wildHealthLinkButton/WildHealthLinkButton";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import {WildHealthPlaceHolder} from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {useFacade} from "./manageDeniedLicensesComponent.hooks";
import moment from "moment";

export const ManageDeniedLicensesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isActionsOpen,
            targetLicenseId,
            deniedLicenses,
            actionsAnchorEl,
            sortingColumns,
            sortingSource,
        },
        handleSorting,
        handleToggleLicenseActions,
    ] = useFacade();

    const history = useHistory();

    if (isLoading) {
        return <WildHealthLinearProgress/>;
    }

    if (!isLoading && (!deniedLicenses || !deniedLicenses.length)) {
        return <WildHealthPlaceHolder message="No current records found"/>
    }

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell width="17.5%" key={index} align="left">
                                        {
                                            (item.source && item.direction) ?
                                                <TableSortLabel
                                                    active={sortingSource === item.source}
                                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                                    onClick={() => handleSorting(item.source, item.direction)}
                                                >
                                                    {item.title}
                                                </TableSortLabel> :
                                                item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell align="right">
                                Actions
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            deniedLicenses.map((license, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        <WildHealthLinkButton
                                            onClick={() => navigationService.toLicense(history, license.id, MenuItemType.DeniedLicenses)}
                                            content={license.licenseeName}/>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.businessName}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.isFellow ? 'Yes' : 'No'}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.reason}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {moment(new Date(license.deniedOn)).format("MM/DD/YYYY")}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="right">
                                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                            handleToggleLicenseActions(!isActionsOpen, license.id, event.currentTarget)}
                                        >
                                            <MoreVertIcon/>
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            {renderTable()}
            <Popover
                keepMounted
                anchorEl={actionsAnchorEl}
                open={isActionsOpen}
                onClose={() => handleToggleLicenseActions(false, 0, null)}
            >
                <WildHealthMenuItem
                    onClick={() => navigationService.toLicense(history, targetLicenseId, MenuItemType.DeniedLicenses)}>
                    Review
                </WildHealthMenuItem>
            </Popover>
        </Box>
    )
}