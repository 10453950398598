import {Store, StoreConfig} from '@datorama/akita';
import {SnackState, SnackType} from './snack.models';

/**
 * Creates initial state
 */
export function createInitialState(): SnackState {
    return {
        isOpen: false,
        messages: [],
        vertical: 'bottom',
        horizontal: 'center',
        type: SnackType.success,
        duration: 3500,
    };
}

/**
 * Provides snackbar states management
 */
@StoreConfig({name: 'snackbar'})
export class SnackStore extends Store<SnackState> {
    constructor() {
        super(createInitialState());
    }
}

export const snackStore = new SnackStore();
