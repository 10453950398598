import { Box } from "@material-ui/core";
import React from 'react';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { Pages } from "../../../../constants/pages.constants";
import { Titles } from "../../../../constants/title.constants";
import { MenuItemType } from "../../../../models/menu.models";
import { ManagePendingLicensesComponent } from "../../components/managePendingLicensesComponent/ManagePendingLicensesComponent";

export const PendingLicensesPage: React.FC = () => {
    return (
        <LayoutComponent title={Titles.pendingLicenses} selectedMenuItem={MenuItemType.PendingLicenses} pageName={<span>{Pages.pendingLicenses}</span>}>
            <Box mt={5} p={5}>
                <ManagePendingLicensesComponent />
            </Box>
        </LayoutComponent>
    )
}