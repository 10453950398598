import { Box, Link, Checkbox, FormControl, FormHelperText } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import { AgreementModel, ConfirmAgreementModel } from "../../models/agreements.models";
import { AgreementDialogComponent } from "../agreementDialogComponent/AgreementDialogComponent";
import { useFacade } from "./confirmAgreementsCheckBoxComponent.hooks";

export interface ConfirmAgreementsCheckBoxComponentProps {
    agreements: AgreementModel[];
    agreementsConfirmations: ConfirmAgreementModel[],
    handleAgreementsConfirm: Function;
    errors: { [id: string]: string; };
}

export const ConfirmAgreementsCheckBoxComponent: React.FC<ConfirmAgreementsCheckBoxComponentProps> = (props: ConfirmAgreementsCheckBoxComponentProps) => {
    const {
        agreements,
        handleAgreementsConfirm,
        errors
    } = props;

    const [
        {
            isAgreementOpen,
            selectedAgreement,
        },
        errorColor,
        handleAgreementDialogToggle,
        isChecked,
        getCheckBoxColor,
        getErrorMessage
    ] = useFacade(props);

    if (!(agreements || agreements.length))
        return <></>;

    return (
        <>
            {
                agreements.map((agreement) => {
                    const isConfirmed = isChecked(agreement);
                    const fill = getCheckBoxColor(agreement);

                    return (
                        <Box key={agreement.id} display="flex">
                            <Box>
                                <FormControl
                                    id={agreement.name}
                                    error={!!errors[agreement.name]}>
                                    <Checkbox
                                        color="primary"
                                        checked={isConfirmed}
                                        icon={<CheckBoxOutlineBlankIcon style={{fill: fill}} />}
                                        checkedIcon={<CheckBoxIcon style={{fill: fill}} />}
                                        onChange={() => handleAgreementsConfirm(agreement, !isConfirmed)}
                                    />
                                </FormControl>
                            </Box>
                            <Box m={1}>
                                <span>
                                    {'I have read and agree to the terms and conditions of the '}
                                </span>
                                <Link
                                    href='#'
                                    className="text-accent uppercase"
                                    onClick={() => handleAgreementDialogToggle(agreement, true)}
                                >
                                    {agreement.displayName}
                                </Link>
                            </Box>
                        </Box>
                    )
                })
            }
            {
                agreements.some(x => errors[x.name]) &&
                <Box mt={1}>
                    <FormHelperText style={{color: errorColor}}>{getErrorMessage()}</FormHelperText>
                </Box>
            }
            <AgreementDialogComponent
                isOpen={isAgreementOpen}
                agreement={selectedAgreement}
                handleClose={() => handleAgreementDialogToggle(null, false)}
            />
        </>
    );
}
