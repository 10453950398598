import React from 'react';
import { ReactComponent as Clarity } from '@img/icons/Clarity.svg';
import { IconProps } from './IconProps';

const ClarityIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Clarity fill={isSelected ? '#1F72A2' : '#AFBACA'} />
    )
}

export default ClarityIcon;