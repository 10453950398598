import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router';

interface PageTitleComponentProps {
    startAdornment?: JSX.Element;
    title: string;
    endAdornment?: JSX.Element;
}

export const PageTitleComponent: React.FC<PageTitleComponentProps> = (props: PageTitleComponentProps) => {
    return (
        <Box display="flex" alignItems='center'>
            {props.startAdornment}

            <Box
                ml={props.startAdornment ? 2 : 0}
                mr={props.endAdornment ? 2 : 0}
            >
                {props.title}
            </Box>

            {props.endAdornment}
        </Box>
    )
}

interface BackTitleComponentProps {
    title: string;
    action?: () => void;
}

export const BackTitleComponent: React.FC<BackTitleComponentProps> = (props: BackTitleComponentProps) => {
    const { title, action } = props;
    const history = useHistory();

    return (
        <PageTitleComponent
            startAdornment={
                <IconButton onClick={() => action ? action() : history.goBack()}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={title}
        />
    )
}
