import LogoIcon from "@img/icons/Logo.svg";
import MenuIcon from '@img/icons/Menu.svg';
import { Drawer, Grid, IconButton, List, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';
import { MenuItemType } from "../../models/menu.models";
import { authQuery } from '../../modules/auth/stores/auth';
import { WildHealthLinkButton } from '../../modules/common/components/wildHealthLinkButton/WildHealthLinkButton';
import { navigationService } from '../../services/navigation.service';
import { useFacade } from './sideBarComponent.hooks';
import { useStyles } from './sideBarComponent.styles';

interface SideBarComponentProps {
    isOpen: boolean;
    selectedMenuItem: MenuItemType,
}

export const SideBarComponent: React.FC<SideBarComponentProps> = (props: SideBarComponentProps) => {
    const {
        isOpen,
        selectedMenuItem,
    } = props;

    const type = authQuery.getUserType();
    const permissions = authQuery.getPermissions();
    const history = useHistory();
    const classes = useStyles();

    const [
        handleToggle,
        renderMenuItems
    ] = useFacade(isOpen, selectedMenuItem, type, permissions, classes);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                })
            }}
        >
            <Toolbar>
                <IconButton onClick={() => handleToggle()} >
                    <img src={MenuIcon} alt="Menu Icon"/>
                </IconButton>
            {
                isOpen &&
                <WildHealthLinkButton
                    onClick={() => navigationService.toBusinesses(history)}
                    content={<img className={classes.logo} src={LogoIcon} alt="img"/>}
                />
            }
            </Toolbar>

            <Grid
                className={classes.content}
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
            >
                <Grid item>
                    <List className={classes.menuList}>
                    {
                        renderMenuItems()
                    }
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    )
}
