import { useEffect } from "react";
import usePlacesAutocomplete, { getGeocode, getZipCode, HookReturn as PlacesHookReturn } from "use-places-autocomplete";
import { snackService } from "../../snack/state";

export const addressesKeys = {
    streetAddress1: 'address1',
    streetAddress2: 'address2',
    city: 'city',
    state: 'state',
    zipCode: 'zipCode',
    country: 'country',
}

export function useFacade(
    prefix: string,
    address1: string,
    handleChanges: Function,
    ): [
        PlacesHookReturn,
        (value: string) => void,
        (value: string) => void
    ] {

    const placesProps = {
        requestOptions: {},
        debounce: 300,
    };

    const state: PlacesHookReturn = usePlacesAutocomplete(placesProps);

    const selectAddress = (value: string) => {
        const address = value.split(', ');

        const street = address[0];
        const city = address[2] ? address[1] : street;
        const state = address[3] ? address[2] : city;
        const country = address[3] ?? address[2] ?? address[1];

        handleChanges(`${prefix}${addressesKeys.streetAddress1}`, street);
        handleChanges(`${prefix}${addressesKeys.city}`, city);
        handleChanges(`${prefix}${addressesKeys.state}`, state);
        handleChanges(`${prefix}${addressesKeys.country}`, country);

        getGeocode({address: value})
            .then(response => getZipCode(response[0], false))
            .then(zipCode => handleChanges(`${prefix}${addressesKeys.zipCode}`, zipCode))
            .catch(error => snackService.error("Failed to get address data."))
    }

    const handleAddressChange = (value: string) => {
        handleChanges(`${prefix}${addressesKeys.streetAddress1}`, value);
        state.setValue(value);
    }

    useEffect(() => {
        state.setValue(address1, false);
    }, [address1]);

    return [state,
        selectAddress,
        handleAddressChange,
    ];
}
