import {ValidationRule} from "./validation-rule";

export const regexpValidationRule = (regexp: RegExp, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return regexp.test(value ?? "");
        },
        message: message ?? 'Value is incorrect.'
    }
}

export const rangeValidationRule = (from: number | null = null, to: number | null = null, message: string = null): ValidationRule => {
    return {
        function: (value: number) => {
            if (from !== null && +value < from) {
                return false;
            }

            return !(to !== null && +value > to);


        },
        message: message ?? `Value should be in range from ${from} to ${to}.`
    }
}

export const nullableRangeValidationRule = (from: number | null = null, to: number | null = null, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            if (value.toString().length === 0) {
                return true;
            }

            if (isNaN(value)) {
                return true;
            }

            const rule = rangeValidationRule(from, to, message);
            return rule.function(value);
        },
        message: message ?? `Value should be in range from ${from} to ${to}.`
    }
}

export const notWhitespaceValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return !value.length ? true : value.trim() !== '';
        },
        message: message ?? `Whitespaces are wrong format.`
    }
}

export const stringLengthValidationRule = (from: number = null, to: number = null, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            if (from !== null && value.length < from) {
                return false;
            }

            return !(to !== null && value.length > to);


        },
        message: message ?? `Length should be in range from ${from} to ${to}.`
    }
}

export const requiredValidationRule = (from: number = null, message = null): ValidationRule => {
    return stringLengthValidationRule(from ?? 1, null, message ?? 'This field is required');
}

export const maxLengthValidationRule = (to: number, message = null): ValidationRule => {
    return stringLengthValidationRule(null, to, message ?? `Max length is ${to}`);
}

export const functionValidationRule = (func: Function, message: string = null): ValidationRule => {
    return {
        function: func,
        message: message ?? `Value is incorrect.`
    }
}

export const emailValidationRule = regexpValidationRule(new RegExp('[\\w-]+@([\\w-]+\\.)+[\\w-]+'), 'Incorrect email address.');

export const phoneNumberValidationRule = regexpValidationRule(new RegExp('^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$'), 'Incorrect phone number.');

export const nationalIdentifierValidationRule = (isRequired: boolean): ValidationRule => {
    const regex = regexpValidationRule(new RegExp('^\\d{10}$'));
    return {
        function: (value: string) => {
            if (isRequired) {
                return regex.function(value);
            }

            if (value && value.length) {
                return regex.function(value);
            }

            return true;
        },
        message: 'National Provider Identifier should contains 10 digits'
    }
}

export const linkSegmentValidationRule = regexpValidationRule(new RegExp('^([\\w\\d]+)(\\-[\\w\\d]+)*$', 'i'), 'Link is invalid.');
