import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { confirmService } from "../../../../services/confirm.service";
import { navigationService } from "../../../../services/navigation.service";
import { licensesService } from '../../services/licenses.service';
import {onEmit} from "../../../common/helpers/on-emit";
import {LicenseModel, LicenseStatus} from "../../models/licenses.models";
import {licensesQuery} from "../../stores/licenses";

export interface ApproveLicensePageState {
    license: LicenseModel;
    isLoading: boolean;
    isSubmitted: boolean;
    isConfirmDenyOpened: boolean;
}

export function useFacade(licenseId: number): [ApproveLicensePageState, () => void, (reason: string) => void, () => void] {
    const [state, setState] = useState({
        license: null,
        isLoading: true,
        isSubmitted: false,
        isConfirmDenyOpened: false,
    } as ApproveLicensePageState);

    const history = useHistory();

    const handleToggleConfirmDeny = () => {
        setState(state => ({...state, isConfirmDenyOpened: !state.isConfirmDenyOpened}));
    }

    const handleDeny = (reason: string) => {
        licensesService.Deny({id: licenseId, reason: reason}).subscribe(r => {
            navigationService.toPendingLicenses(history);
        });
    }

    const handleApprove = () => {
        confirmService.confirm(`Are you sure you want to approve this application?`).subscribe(() => {
            setState(state => ({...state, isSubmitted: true}));

            licensesService.Approve({id: licenseId}).subscribe(() => {
                setState(state => ({...state, isSubmitted: false}));
                navigationService.toActiveLicenses(history);
            });
        });
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<LicenseModel>(licensesQuery.targetLicense$, license => {
                if (license && license.id === licenseId) {
                    if (license.status !== LicenseStatus.Pending) {
                        navigationService.toLicense(history, licenseId);
                    }
        
                    setState(state => ({...state, license: license}));
                }
            })
        ];

        licensesService.getById(licenseId).subscribe(
            () => setState(state => ({ ...state, isLoading: false})),
            () => setState(state => ({ ...state, isLoading: false}))
        )

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [licenseId, history])

    return [state, handleToggleConfirmDeny, handleDeny, handleApprove];
}
