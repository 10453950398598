export enum PermissionType
{
    None = 0,
    Coaching = 5,
    AddonOrders = 10,
    Shortcuts = 20,
    Appointments = 25,
    EnrollmentNotifications = 30,
    ManageEmployees = 35,
    ManagePatients = 40,
    HealthReportGeneration = 45,
    VisitNoteCreation = 50,
    ClinicAnalytics = 55,
    LabsModification = 60,
    ViewOnly = 65,
    PatientAnalytics = 70,
    LocationAnalytics = 75,
    LmdPatientSignUpPage = 80,
    ManageLicenses = 85,
    ManageLocations = 90,
    MigrationBetweenLocations = 95
}

export enum UserType
{
    Unknown = 0,
    Patient = 1,
    Employee = 2
}
