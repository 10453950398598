import {AddressModel} from "../../common/models/address.model";

export interface PracticeBaseModel {
    name: string;
    preferredUrl: string;
    address: AddressModel;
    phoneNumber: string;
    email: string;
}

export interface PracticeModel extends PracticeBaseModel {
    id: number;
    logo: null;
}

export interface RegisterPracticeModel extends PracticeBaseModel {

}

export const getDefaultRegisterPracticeModel = () => {
    return {
        name: '',
        email: '',
        phoneNumber: '',
        preferredUrl: '',
        address: {
            address1: '',
            address2: '',
            country: 'US',
            city: '',
            state: '',
            zipCode: '',
        }
    };
}