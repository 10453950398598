import {Box} from "@material-ui/core";
import React from 'react';
import {useHistory} from "react-router";
import {LayoutComponent} from "../../../../components/layoutComponent/LayoutComponent";
import {BackTitleComponent} from "../../../../components/pageTitleComponent/PageTitleComponent";
import {Pages} from "../../../../constants/pages.constants";
import {Titles} from "../../../../constants/title.constants";
import {MenuItemType} from "../../../../models/menu.models";
import {LicenseComponent} from "../../components/licenseComponent/LicenseComponent";
import {useFacade} from "./licensePage.hooks";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import { navigationService } from "../../../../services/navigation.service";

export const LicensePage: React.FC = (props: any) => {
    const licenseId = Number(props.match.params.licenseId);

    const selectedMenuItem = props?.location?.state?.selectedMenuItem;
    const backPath = props?.location?.state?.backPath;

    const history = useHistory();
    const backAction = backPath ? () => navigationService.to(history, backPath) : null;

    const [
        {
            isLoading,
            license
        }
    ] = useFacade(licenseId);

    const pageName = <BackTitleComponent title={Pages.license} action={backAction}/>;

    return (
        <LayoutComponent
            title={Titles.license}
            selectedMenuItem={selectedMenuItem ?? MenuItemType.Licenses}
            pageName={pageName}
        >
            <Box mt={5} p={5}>
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : <LicenseComponent license={license}/>
            }
            </Box>
        </LayoutComponent>
    )
}