import { Store, StoreConfig } from '@datorama/akita';

export interface ApplicationState {
    any: any;
    isSideBarOpen: boolean;
}

/**
 * Creates initial state
 */
export function createInitialState(): ApplicationState {
    return {
        any: {},
        isSideBarOpen: false
    };
}

/**
 * Provides application state management
 */
@StoreConfig({name: 'application'})
export class ApplicationStore extends Store<ApplicationState> {
    constructor() {
        super(createInitialState());
    }
}

export const applicationStore = new ApplicationStore();
