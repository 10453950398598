import { AppBar, Box, Button, Toolbar, useScrollTrigger } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from "./headerComponent.styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { navigationService } from './../../services/navigation.service';

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

interface HeaderComponentProps {
    title: JSX.Element;
    isSideBarOpen: boolean;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (props: HeaderComponentProps) => {
    const {
        title,
        isSideBarOpen,
    } = props;

    const classes = useStyles();

    return (
        <ElevationScroll>
            <AppBar className={clsx(classes.root, {
                [classes.drawerOpen]: isSideBarOpen,
                [classes.drawerClose]: !isSideBarOpen,
            })}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>{title}</div>

                    <div className={classes.grow} />

                    <Button
                        color="inherit"
                        onClick={() => navigationService.toClarityPortalApp()}
                    >
                        <Box mr={1}>
                            Go To Portal
                        </Box>
                        <ExitToAppIcon/>
                    </Button>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
