import React from 'react';
import {Box, Link, TextField} from "@material-ui/core";
import {PracticeBaseModel} from "../../models/practice.model";

export interface PracticeSettingsFormComponentProps {
    model: PracticeBaseModel;
    errors?: { [id: string]: string; }
    handleChanges?: Function;
}

export const PracticeSettingsFormComponent: React.FC<PracticeSettingsFormComponentProps> = (props: PracticeSettingsFormComponentProps) => {
    const {
        model,
        errors,
        handleChanges
    } = props;

    const renderEditForm = () => {
        return (
            <Box>
                <Box>
                    <span className="size-1_25">
                        Preferred URL:
                    </span>
                </Box>
                <Box width={1} mt={2} display="flex" justifyContent="flex-start">
                    <Box display="flex" mt={1.5}>
                        <Box className="text-medium">
                            app.wildhealth.com/join/
                        </Box>
                    </Box>
                    <Box ml={1} minWidth='120px' alignItems="center">
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={model.preferredUrl}
                            label="Preferred Url"
                            error={!!errors['preferredUrl']}
                            helperText={errors['preferredUrl']}
                            onChange={(v) => handleChanges('preferredUrl', v.target.value)}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderViewItem =(key: string, value: any) => {
        return (
            <Box mt={2} display="flex">
                <Box width={'150px'}>
                    <span className="text-bold">
                        {key}:
                    </span>
                </Box>
                <Box>{value}</Box>
            </Box>
        )
    }

    const renderPreferredLink = () => {
        const url = `app.wildhealth.com/join/${model.preferredUrl}`;

        return (
            <Link onClick={() => window.open(`https://${url}`)} component="button">
                {url}
            </Link>
        )
    }

    const renderView = () => {
        return (
            <Box>
                {renderViewItem('Preferred URL', renderPreferredLink())}
            </Box>
        )
    }

    return handleChanges ? renderEditForm() : renderView();

}