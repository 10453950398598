import { CssBaseline, Toolbar } from '@material-ui/core';
import React from 'react';
import { MenuItemType } from "../../models/menu.models";
import { HeaderComponent } from '../headerComponent/HeaderComponent';
import { SideBarComponent } from "../sideBarComponent/SideBarComponent";
import { useFacade } from './layoutComponent.hooks';
import { useStyles } from './layoutComponent.styles';
import { WildHealthContainer } from '../../modules/common/components/wildHealthContainer/WildHealthContainer';

interface LayoutComponentProps {
    title: string;
    pageName?: JSX.Element;
    selectedMenuItem: MenuItemType;
    children: React.ReactNode;
}

export const LayoutComponent: React.FC<LayoutComponentProps> = (props: LayoutComponentProps) => {
    const {
        title,
        pageName,
        selectedMenuItem,
        children,
    } = props;

    const classes = useStyles();

    const [
        {
            isSideBarOpen,
        },
    ] = useFacade();

    return (
        <div className={classes.root}>
            <title>{title}</title>
            <CssBaseline/>
            <HeaderComponent title={pageName} isSideBarOpen={isSideBarOpen} />
            <SideBarComponent selectedMenuItem={selectedMenuItem} isOpen={isSideBarOpen} />
            <main className={classes.content}>
                <Toolbar/>
                <WildHealthContainer>
                    {children}
                </WildHealthContainer>
            </main>
        </div>
    );
}
