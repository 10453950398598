import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from "react-router";
import { SITE_KEY } from "../../../../config";
import { navigationService } from "../../../../services/navigation.service";
import { AgreementModel } from "../../../agreements/models/agreements.models";
import { agreementsService } from "../../../agreements/services/agreements.service";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { IErrorState } from "../../../common/validation/error-state";
import { GeneralValidator } from "../../../common/validation/general-validator";
import { getDefaultRegisterBusinessModel } from "../../models/business.model";
import { MedicalLicenseNumberModel } from "../../models/medicalLicense.model";
import { getDefaultRegisterPracticeModel } from "../../models/practice.model";
import { getDefaultRegisterProviderModel } from "../../models/provider.model";
import { StartBusinessModel } from "../../models/startBusiness.model";
import { businessesService } from "../../services/businesses.service";
import { businessValidator } from "./businessValidator";
import { practiceValidator } from "./practice.validator";
import { providerValidator } from "./provider.validator";

const context = {};

export interface GetStartedPageState {
    isSubmitted: boolean;
    isButtonDisabled: boolean;
    businessAddressSameAsBillingAddress: boolean;
    practiceAddressSameAsBillingAddress: boolean;
    practiceAddressSameAsBusinessAddress: boolean;
    model: StartBusinessModel;
}

export interface AgreementsValidationState extends IErrorState {
    agreements: AgreementModel[];
}

export interface ProviderValidationState extends IErrorState {

}

export interface BusinessValidationState extends IErrorState {

}

export interface PracticeValidationState extends IErrorState {

}

export function useFacade(): [
    GetStartedPageState,
    AgreementsValidationState,
    IErrorState,
    IErrorState,
    IErrorState,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function
] {
    const history = useHistory();
    const recaptcha = useRef(null);
    const [agreementsState, setAgreementsState] = useState({ agreements: [], errors: {} } as AgreementsValidationState);
    const [providerErrors, setProviderErrors] = useState({ errors: {} } as ProviderValidationState);
    const [businessErrors, setBusinessErrors] = useState({ errors: {} } as BusinessValidationState);
    const [practiceErrors, setPracticeErrors] = useState({ errors: {} } as PracticeValidationState);
    const [state, setState] = useState({
        businessAddressSameAsBillingAddress: false,
        practiceAddressSameAsBillingAddress: false,
        practiceAddressSameAsBusinessAddress: false,
        isSubmitted: false,
        isButtonDisabled: true,
        model: {
            provider: getDefaultRegisterProviderModel(),
            business: getDefaultRegisterBusinessModel(),
            practice: getDefaultRegisterPracticeModel(),
            antiSpamToken: '',
            confirmations: []
        },
    } as GetStartedPageState);

    const validateLicense = (license: MedicalLicenseNumberModel, field: string, value: string) => {
        const errors = providerErrors.errors;
        const error = providerValidator.validate(field, value);
        if (error) {
            errors[`licence-${license.id}-${field}`] = error;
        } else if (errors[`licence-${license.id}-${field}`]) {
            delete errors[`licence-${license.id}-${field}`];
        }

        state.model.provider.medicalLicenses.forEach(l =>
            l[field] === license[field] && l.id !== license.id ?
                errors[`licence-${license.id}-${field}`] = 'This value already exist' :
                delete errors[`licence-${license.id}-${field}`]
        );

        setProviderErrors(state => ({
            ...state,
            errors: errors
        }))
    }

    const validateBillingAddress = () => {
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.address1", state.model.business.billingAddress.address1);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.address2", state.model.business.billingAddress.address2);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.city", state.model.business.billingAddress.city);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.country", state.model.business.billingAddress.country);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.state", state.model.business.billingAddress.state);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "billingAddress.zipCode", state.model.business.billingAddress.zipCode);

        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.address1", state.model.business.address.address1);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.address2", state.model.business.address.address2);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.city", state.model.business.address.city);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.country", state.model.business.address.country);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.state", state.model.business.address.state);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.zipCode", state.model.business.address.zipCode);
    }

    const validateBusinessAddress = () => {
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.address1", state.model.business.address.address1);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.address2", state.model.business.address.address2);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.city", state.model.business.address.city);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.country", state.model.business.address.country);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.state", state.model.business.address.state);
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, "address.zipCode", state.model.business.address.zipCode);
    }

    const validateBusinessesAddress = () => {
        validateBillingAddress();
        validateBusinessAddress();
    }

    const validatePracticeAddress = () => {
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.address1", state.model.practice.address.address1);
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.address2", state.model.practice.address.address2);
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.city", state.model.practice.address.city);
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.country", state.model.practice.address.country);
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.state", state.model.practice.address.state);
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, "address.zipCode", state.model.practice.address.zipCode);
    }

    const validateForm = (): boolean => {
        providerValidator.validateObjectAndSetState(providerErrors, setProviderErrors, state.model.provider);
        state.model.provider.medicalLicenses.forEach((license) => {
            validateLicense(license, 'state', license.state);
        })

        businessValidator.validateObjectAndSetState(businessErrors, setBusinessErrors, state.model.business);

        validateBusinessesAddress();

        practiceValidator.validateObjectAndSetState(practiceErrors, setPracticeErrors, state.model.practice);

        validatePracticeAddress();

        agreementsState.agreements.forEach(agreement => {
            const confirmation = state.model.confirmations.find(x => x.agreementId === agreement.id);
            if (!confirmation.isConfirmed) {
                GeneralValidator.addError(agreementsState, agreement.name, 'All agreements should be confirmed.');
                setState({ ...state });
            }
        });

        return providerValidator.stateIsValid(providerErrors) &&
            businessValidator.stateIsValid(businessErrors) &&
            practiceValidator.stateIsValid(practiceErrors) &&
            Object.keys(agreementsState.errors).length === 0;
    }

    const handleProviderChanges = (field: string, value: string) => {
        providerValidator.validateAndSetState(providerErrors, setProviderErrors, field, value);

        const provider = state.model.provider;
        provider[field] = value;

        setState({
            ...state,
            model: {
                ...state.model,
                provider: provider
            }
        });
        handleStateButton();
    }

    const handleStateButton = () => {
        const provider = state.model.provider;
        const buisness = state.model.business;
        const practice = state.model.practice;


        let isAgeementsConfirm = true;

        agreementsState.agreements.forEach(agreement => {
            const confirmation = state.model.confirmations.find(x => x.agreementId === agreement.id);
            if (!confirmation.isConfirmed) {
                isAgeementsConfirm = false;
            }
        });

        if (provider.firstName
            && provider.lastName
            && provider.credentials
            && provider.phoneNumber
            && provider.email
            && provider.password
            && provider.confirmPassword
            && buisness.address.address1
            && buisness.address.city
            && buisness.address.state
            && buisness.address.zipCode
            && buisness.name
            && buisness.phoneNumber
            &&
            (
                buisness.billingAddress.address1
                && buisness.billingAddress.city
                && buisness.billingAddress.state
                && buisness.billingAddress.zipCode
            )
            || state.businessAddressSameAsBillingAddress
            &&
            (
                practice.address.address1
                && practice.address.city
                && practice.address.state
                && practice.address.zipCode
            )
            || state.practiceAddressSameAsBillingAddress
            || state.practiceAddressSameAsBusinessAddress
            && practice.name
            && practice.email
            && practice.phoneNumber
            && practice.preferredUrl
            && isAgeementsConfirm
        ) {
            setState(state => ({
                ...state,
                isButtonDisabled: false
            }));
        }
        else {
            setState(state => ({
                ...state,
                isButtonDisabled: true
            }));
        }

    }

    const handleLicenseChanges = (id: number, field: string, value: string) => {
        const license = state.model.provider.medicalLicenses.find(x => x.id === id);
        if (!license) {
            return;
        }

        validateLicense(license, field, value);

        license[field] = value.toUpperCase();

        setState(state => ({
            ...state,
            model: {
                ...state.model,
                provider: {
                    ...state.model.provider,
                    medicalLicenses: state.model.provider.medicalLicenses.map(x => x.id === id ? license : x)
                }
            }
        }))
        handleStateButton();
    }

    const handleAddLicense = () => {
        const newLicense = {
            id: moment().unix() + state.model.provider.medicalLicenses.length,
            state: '',
            number: ''
        }

        state.model.provider = {
            ...state.model.provider,
            medicalLicenses: [...state.model.provider.medicalLicenses, newLicense]
        }

       handleStateButton()
    }

    const handleRemoveLicense = (license: MedicalLicenseNumberModel) => {
        state.model.provider = {
            ...state.model.provider,
            medicalLicenses: state.model.provider.medicalLicenses.filter(x => x.id !== license.id)
        }

        const errors = providerErrors.errors;

        if (errors[`licence-${license.id}-state`]) {
            delete errors[`licence-${license.id}-state`];
        }

        setProviderErrors(state => ({
            ...state,
            errors: errors
        }));
        handleStateButton();
    }

    const handleBusinessChanges = (field: string, value: string) => {
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, field, value);

        const business = state.model.business;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, business);
        lastObject[key] = value;

        setState(state => ({
            ...state,
            model: {
                ...state.model,
                business: business
            }
        }));
        handleStateButton();
    }

    const handleBusinessAddressSameAsBillingAddress = (value: boolean) => {
        const business = state.model.business;
        const practice = state.model.practice;

        if (value) {
            business.billingAddress = Object.assign(business.address);

            if (state.practiceAddressSameAsBillingAddress) {
                practice.address = Object.assign(business.address);
            }
        } else {
            business.billingAddress = state.practiceAddressSameAsBillingAddress ?
                Object.assign({}, practice.address) :
                Object.assign({}, business.address);
        }

        setState(state => ({
            ...state,
            businessAddressSameAsBillingAddress: value,
            model: {
                ...state.model,
                business: business,
                practice: practice
            }
        }));

        validateBusinessesAddress();
        handleStateButton();
    }

    const handlePracticeAddressSameAsBillingAddress = (value: boolean) => {
        const practice = state.model.practice;
        const business = state.model.business;

        if (value) {
            practice.address = state.businessAddressSameAsBillingAddress ?
                Object.assign(business.address) :
                Object.assign(business.billingAddress);
        } else {
            practice.address = Object.assign({}, business.billingAddress);
        }

        setState(state => ({
            ...state,
            practiceAddressSameAsBillingAddress: value,
            practiceAddressSameAsBusinessAddress: false,
            model: {
                ...state.model,
                practice: practice
            }
        }));

        validatePracticeAddress();
        handleStateButton();
    }

    const handlePracticeAddressSameAsBusinessAddress = (value: boolean) => {
        const practice = state.model.practice;
        const business = state.model.business;

        if (value) {
            practice.address = Object.assign(business.address);
        } else {
            practice.address = Object.assign({}, business.address);
        }

        setState(state => ({
            ...state,
            practiceAddressSameAsBusinessAddress: value,
            practiceAddressSameAsBillingAddress: false,
            model: {
                ...state.model,
                practice: practice
            }
        }));

        validatePracticeAddress();
        handleStateButton();
    }

    const handlePaymentSubmit = (paymentToken: string) => {
        const business = state.model.business;
        businessValidator.validateAndSetState(businessErrors, setBusinessErrors, 'paymentToken', paymentToken);
        business.paymentToken = paymentToken;

        setState(state => ({
            ...state,
            model: {
                ...state.model,
                business: business
            }
        }));
        handleStateButton();
    }

    const handlePracticeChanges = (field: string, value: string) => {
        practiceValidator.validateAndSetState(practiceErrors, setPracticeErrors, field, value);

        const practice = state.model.practice;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, practice);
        lastObject[key] = value;

        setState(state => ({
            ...state,
            model: {
                ...state.model,
                practice: practice
            }
        }));
        handleStateButton();
    }

    const handleAgreementsConfirm = (agreement: AgreementModel, isConfirmed: boolean) => {
        const item = state.model.confirmations.find(i => i.agreementId === agreement.id);

        if (item) {
            item.isConfirmed = isConfirmed;
            setState(state => ({ ...state }));

            if (agreementsState.errors[agreement.name]) {
                delete agreementsState.errors[agreement.name];
                setAgreementsState(state => ({ ...state }));
            }
        }
        handleStateButton();
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            return;
        }

        setState(state => ({
            ...state,
            isSubmitted: true
        }));

        executeRecaptcha();
    }

    const submitBusiness = (antiSpamToken: string) => {
        const state = context['state'];
        const model = state.model;
        model.antiSpamToken = antiSpamToken;

        state.model.business.name = state.model.business.name.trim();
        state.model.practice.email = state.model.practice.email.trim();
        state.model.provider.email = state.model.provider.email.trim();

        businessesService.start(model).subscribe(
            response => {
                setState(state => ({
                    ...state,
                    isSubmitted: false
                }));

                navigationService.toGetStartedSucceed(history, response)
            },
            () => {
                setState(state => ({
                    ...state,
                    isSubmitted: false
                }));
            }
        )
    }

    const executeRecaptcha = () => {
        recaptcha.current?.reset();
        recaptcha.current?.execute()
    }

    const recaptchaOnResolved = (antiSpamToken: string) => submitBusiness(antiSpamToken);

    const renderRecaptcha = () => {
        context['state'] = state;

        return <Recaptcha
            ref={recaptcha}
            sitekey={SITE_KEY}
            onResolved={(token) => recaptchaOnResolved(token)}
        />
    }

    useEffect(() => {
        const subscriptions = [];

        agreementsService.getActual().subscribe(agreements => {
            setAgreementsState(state => ({ ...state, agreements: agreements }));
            setState(state => ({ ...state, model: { ...state.model, confirmations: agreements.map(i => { return { agreementId: i.id, isConfirmed: false } }) } }));
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [
        state,
        agreementsState,
        providerErrors,
        businessErrors,
        practiceErrors,
        handleProviderChanges,
        handleLicenseChanges,
        handleAddLicense,
        handleRemoveLicense,
        handleBusinessChanges,
        handleBusinessAddressSameAsBillingAddress,
        handlePracticeAddressSameAsBillingAddress,
        handlePracticeAddressSameAsBusinessAddress,
        handleAgreementsConfirm,
        handlePracticeChanges,
        handlePaymentSubmit,
        renderRecaptcha,
        handleSubmit
    ];
}
