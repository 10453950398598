import { createStyles, makeStyles, Theme } from "@material-ui/core";

const drawerWidth = 300;
const drawerCloseWidth = 92;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            border: "none",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            width: drawerCloseWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        content: {
            height: '100%',
            width: '100%',
        },
        menuList: {
            padding: 14,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        menuIcon: {
            minWidth: 0,
        },
        menuTitle: {
            marginLeft: 20,
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 19,
        },
        menuItem: {
            borderRadius: 2,
            height: 40,
            margin: '4px 0',
            paddingLeft: 20,
            paddingRight: 20,
            color: '#7C8A9D',
        },
        menuItemSelected: {
            background: 'rgba(37, 102, 136, 0.06) !important',
            color: '#1f72a2',
        },
        toggleButton: {
            position: 'absolute',
            borderRadius: 0,
            width: '100%',
            bottom: 0,
            padding: 10,
        },
        logo: {
            height: 19,
            paddingLeft: 30,
        },
        nested: {
          paddingLeft: theme.spacing(4),
        },
    }),
);