import logo from "@img/WH_Clarity_Logo.png";
import { Box, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Paper, TextField } from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import React from 'react';
import { PAYMENT_KEY } from '../../../../config';
import { Titles } from '../../../../constants/title.constants';
import { AddressFormComponent } from "../../../common/components/addressFormComponent/AddressFormComponent";
import { BusinessFormComponent } from "../../components/businessFormComponent/BusinessFormComponent";
import { PaymentFormComponent } from '../../../payments/components/paymentFormComponent/PaymentFormComponent';
import { PracticeFormComponent } from "../../components/practiceFormComponent/PracticeFormComponent";
import { PracticeSettingsFormComponent } from "../../components/practiceSettingsFormComponent/PracticeSettingsFormComponent";
import { ProviderFormComponent } from "../../components/providerFormComponent/ProviderFormComponent";
import { MedicalLicenseNumberModel } from "../../models/medicalLicense.model";
import { ConfirmAgreementsCheckBoxComponent } from "../../../agreements/components/confirmAgreementsCheckBoxComponent/ConfirmAgreementsCheckBoxComponent";
import { useFacade } from "./getStartedPage.hooks";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";

export const GetStartedPage: React.FC = () => {
    const [
        {
            businessAddressSameAsBillingAddress,
            practiceAddressSameAsBillingAddress,
            practiceAddressSameAsBusinessAddress,
            model,
            isSubmitted,
            isButtonDisabled,
        },
        agreementsState,
        providerErrors,
        businessErrors,
        practiceErrors,
        handleProviderChanges,
        handleLicenseChanges,
        handleAddLicense,
        handleRemoveLicense,
        handleBusinessChanges,
        handleBusinessAddressSameAsBillingAddress,
        handlePracticeAddressSameAsBillingAddress,
        handlePracticeAddressSameAsBusinessAddress,
        handleAgreementsConfirm,
        handlePracticeChanges,
        handlePaymentSubmit,
        renderRecaptcha,
        handleSubmit,
    ] = useFacade();

    const renderPortalLoginForm = () => {
        return (
            <>
                <form autoComplete="off" style={{ width: '100%' }}>
                    <Box width={1} height="75px">
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={model.provider.email}
                            label="Email"
                            error={!!providerErrors.errors['email']}
                            helperText={providerErrors.errors['email']}
                            onChange={(v) => {
                                handleProviderChanges('email', v.target.value)
                            }}
                        />
                    </Box>
                    <Box width={1} height="75px">
                        <TextField
                            required
                            fullWidth
                            size="small"
                            type="password"
                            variant="outlined"
                            value={model.provider.password}
                            label="Password"
                            error={!!providerErrors.errors['password']}
                            helperText={providerErrors.errors['password']}
                            onChange={(v) => {
                                handleProviderChanges('password', v.target.value)
                            }}
                        />
                    </Box>
                    <Box width={1} height="75px">
                        <TextField
                            required
                            fullWidth
                            size="small"
                            type="password"
                            variant="outlined"
                            value={model.provider.confirmPassword}
                            label="Confirm Password"
                            error={!!providerErrors.errors['confirmPassword']}
                            helperText={providerErrors.errors['confirmPassword']}
                            onChange={(v) => {
                                handleProviderChanges('confirmPassword', v.target.value)
                            }}
                        />
                    </Box>
                </form>
            </>
        )
    }

    const renderLicenceForm = (license: MedicalLicenseNumberModel) => {
        return (
            <Box key={license.id} display="flex" justifyContent="space-between" height="90px">
                <Box width={0.25}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={license.state}
                        label="State"
                        error={!!providerErrors.errors[`licence-${license.id}-state`]}
                        helperText={providerErrors.errors[`licence-${license.id}-state`]}
                        onChange={(v) => {
                            handleLicenseChanges(license.id, 'state', v.target.value)
                        }}
                    />
                </Box>
                <Box width={0.65} px={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={license.number}
                        label="Number"
                        error={!!providerErrors.errors[`licence-${license.id}-number`]}
                        helperText={providerErrors.errors[`licence-${license.id}-number`]}
                        onChange={(v) => {
                            handleLicenseChanges(license.id, 'number', v.target.value)
                        }}
                    />
                </Box>
                <Box width={0.1} pt={0.5}>
                    <Button size="small" color='secondary' onClick={() => handleRemoveLicense(license)}>
                        <DeleteOutlineOutlinedIcon />
                    </Button>
                </Box>
            </Box>
        )
    }

    const renderLicenceesForm = () => {
        return (
            <>
                <Box>
                    <span>
                        What state are you licensed in?
                    </span>
                </Box>
                <Box mt={2}>
                    {
                        model.provider.medicalLicenses.map((license) => renderLicenceForm(license))
                    }
                </Box>
                <Box pt={1}>
                    <Button className="wh-button" onClick={() => handleAddLicense()}>
                        Add Additional State license
                    </Button>
                </Box>
                <Box pt={5}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.provider.nationalIdentifier}
                        label="National Provider Identifier"
                        error={!!providerErrors.errors[`nationalIdentifier`]}
                        helperText={providerErrors.errors[`nationalIdentifier`]}
                        onChange={(v) => {
                            handleProviderChanges('nationalIdentifier', v.target.value)
                        }}
                    />
                </Box>
                <Box pt={5}>
                    <span>
                        Are you currently enrolled or have you completed our fellowship program?
                    </span>
                </Box>
                <Box pt={2} display="flex">
                    <Box>
                        <Button
                            onClick={() => handleProviderChanges('isFellowship', !model.provider.isFellowship)}
                            className={!model.provider.isFellowship ? 'wh-button' : ''}>
                            No
                        </Button>
                    </Box>
                    <Box pl={5}>
                        <Button
                            onClick={() => handleProviderChanges('isFellowship', !model.provider.isFellowship)}
                            className={model.provider.isFellowship ? 'wh-button' : ''}>
                            Yes
                        </Button>
                    </Box>
                </Box>
            </>
        )
    }

    const renderPaymentForm = () => {
        return (
            <PaymentFormComponent
                publicKey={PAYMENT_KEY}
                error={businessErrors.errors['paymentToken']}
                setPaymentToken={(paymentToken: string) => { handlePaymentSubmit(paymentToken) }}
            />
        )
    }

    const renderSubmitButton = () => {
        if (isSubmitted) {
            return <WildHealthLinearProgress />
        }

        return (
            <Button
                disabled={isSubmitted || isButtonDisabled}
                className="wh-button"
                onClick={() => handleSubmit()}>
                Confirm & Proceed
            </Button>
        )
    }

    return (
        <Paper className="wh-form">
            <title>{Titles.getStarted}</title>

            <Box p={2}>
                <img style={{ height: 75 }} src={logo} alt="img" />
            </Box>

            <Container maxWidth="lg">
                <Box py={5}>
                    <Box textAlign="center">
                        <span className="size-2 uppercase">
                            LICENSING PROVIDER REGISTRATION FORM
                        </span>
                    </Box>
                    <Box pt={10}>
                        <Grid container spacing={5}>
                            <Grid item md={5} sm={12} xs={12}>
                                <Box>
                                    <Box>
                                        <span className="size-1_25 uppercase text-accent">
                                            Provider Information
                                        </span>
                                    </Box>
                                    <Box pt={3}>
                                        <ProviderFormComponent
                                            model={model.provider}
                                            handleChanges={handleProviderChanges}
                                            errors={providerErrors.errors} />
                                    </Box>
                                    <Box pt={5}>
                                        {
                                            renderLicenceesForm()
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>

                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <Box>
                                    <Box>
                                        <span className="size-1_25 uppercase text-accent">
                                            Portal Login Info
                                        </span>
                                    </Box>
                                    <Box pt={3}>
                                        {
                                            renderPortalLoginForm()
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box py={5}>
                                    <Divider />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Box>
                                    <Box>
                                        <span className="size-1_25 uppercase text-accent">
                                            Business Information
                                        </span>
                                    </Box>
                                    <Box pt={3}>
                                        <BusinessFormComponent
                                            model={model.business}
                                            errors={businessErrors.errors}
                                            handleChanges={handleBusinessChanges}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>

                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <span className="size-1_25 uppercase text-accent">
                                            Billing Address
                                        </span>
                                        <Box display="flex" justifyContent="flex-end" ml={3} mt={-1}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        icon={<CheckBoxOutlineBlankIcon style={{ fill: "#537b98" }} />}
                                                        color="primary"
                                                        checkedIcon={<CheckBoxIcon style={{ fill: "#537b98" }} />}
                                                        checked={businessAddressSameAsBillingAddress}
                                                        onChange={(e) => {
                                                            handleBusinessAddressSameAsBillingAddress(e.target.checked)
                                                        }} />
                                                }
                                                label="Same as business address"
                                            />
                                        </Box>
                                    </Box>
                                    <Box mt={2}>
                                        <AddressFormComponent
                                            errors={businessErrors.errors}
                                            prefix="billingAddress."
                                            address={model.business.billingAddress}
                                            handleChanges={handleBusinessChanges}
                                            disabled={businessAddressSameAsBillingAddress}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Box mt={3}>
                                        <span className="size-1_25 uppercase text-accent">
                                            Payment Information
                                        </span>
                                    </Box>
                                    <Box mt={3}>
                                        {renderPaymentForm()}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box py={5}>
                                    <Divider />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Box>
                                    <Box>
                                        <span className="size-1_25 uppercase text-accent">
                                            Practice Information
                                        </span>
                                    </Box>
                                    <Box mt={3}>
                                        <Box display="flex">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon style={{ fill: "#537b98" }} />}
                                                        color="primary"
                                                        checkedIcon={<CheckBoxIcon style={{ fill: "#537b98" }} />}
                                                        checked={practiceAddressSameAsBillingAddress}
                                                        onChange={(e) => {
                                                            handlePracticeAddressSameAsBillingAddress(e.target.checked)
                                                        }} />
                                                }
                                                label="Same as billing address"
                                            />
                                        </Box>
                                    </Box>
                                    <Box mt={3}>
                                        <Box display="flex">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon style={{ fill: "#537b98" }} />}
                                                        color="primary"
                                                        checkedIcon={<CheckBoxIcon style={{ fill: "#537b98" }} />}
                                                        checked={practiceAddressSameAsBusinessAddress}
                                                        onChange={(e) => {
                                                            handlePracticeAddressSameAsBusinessAddress(e.target.checked)
                                                        }} />
                                                }
                                                label="Same as business address"
                                            />
                                        </Box>
                                    </Box>
                                    <Box pt={3}>
                                        <PracticeFormComponent
                                            model={model.practice}
                                            errors={practiceErrors.errors}
                                            handleChanges={handlePracticeChanges}
                                            disabledAddress={practiceAddressSameAsBillingAddress || practiceAddressSameAsBusinessAddress}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>

                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Box>
                                    <PracticeSettingsFormComponent
                                        model={model.practice}
                                        errors={practiceErrors.errors}
                                        handleChanges={handlePracticeChanges}
                                    />
                                </Box>
                                <Box>
                                    <ConfirmAgreementsCheckBoxComponent
                                        agreements={agreementsState.agreements}
                                        agreementsConfirmations={model.confirmations}
                                        handleAgreementsConfirm={handleAgreementsConfirm}
                                        errors={agreementsState.errors}
                                    />
                                </Box>
                                <Box pt={5}>
                                    {renderSubmitButton()}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {renderRecaptcha()}

            </Container>
        </Paper>
    )
}