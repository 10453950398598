import {MedicalLicenseNumberModel} from "./medicalLicense.model";

export interface ProviderBaseModel {
    firstName: string;
    middleName: string;
    lastName: string;
    credentials: string;
    email: string;
    phoneNumber: string;
    medicalLicenses: MedicalLicenseNumberModel[];
    nationalIdentifier: string;
    isFellowship: boolean;
}

export interface RegisterProviderModel extends ProviderBaseModel {
    password: string;
    confirmPassword: string;
}

export const getDefaultRegisterProviderModel = () => {
    return {
        firstName: '',
        middleName: '',
        lastName: '',
        credentials: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        medicalLicenses: [
            {
                id: 1,
                state: '',
                number: ''
            }
        ],
        nationalIdentifier: '',
        isFellowship: false,
    };
}

export interface ProviderModel extends ProviderBaseModel {
    id: number;
    email: string;
}

export const getDefaultProviderModel = () => {
    return {
        id: 0,
        firstName: '',
        middleName: '',
        lastName: '',
        credentials: '',
        phoneNumber: '',
        email: '',
        medicalLicenses: [
            {
                id: 1,
                state: '',
                number: ''
            }
        ],
        nationalIdentifier: '',
        isFellowship: false,
    };
}
