import {Box, IconButton, Popover, TableSortLabel} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import React from 'react';
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import {WildHealthLinkButton} from "../../../common/components/wildHealthLinkButton/WildHealthLinkButton";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import {WildHealthPlaceHolder} from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {ConfirmDenySubmissionDialogComponent} from "../confirmWithReasonDialogComponent/ConfirmWithReasonDialogComponent";
import {useFacade} from "./managePendingLicensesComponent.hooks";

export const ManagePendingLicensesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isActionsOpen,
            isInProgress,
            isConfirmDenyOpened,
            targetLicenseId,
            pendingLicenses,
            actionsAnchorEl,
            sortingColumns,
            sortingSource,
        },
        handleSorting,
        handleReviewLicense,
        handleToggleLicenseActions,
        handleToggleDenyLicense,
        handleApproveLicense,
        handleDenyLicense,
    ] = useFacade();

    if (isLoading) {
        return <WildHealthLinearProgress/>;
    }

    if (!isLoading && (!pendingLicenses || !pendingLicenses.length)) {
        return <WildHealthPlaceHolder message="No current records found"/>
    }

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell width="25%" key={index} align="left">
                                        <TableSortLabel
                                            active={sortingSource === item.source}
                                            direction={sortingSource === item.source ? item.direction : 'asc'}
                                            onClick={() => handleSorting(item.source, item.direction)}
                                        >
                                            {item.title}
                                        </TableSortLabel>
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell align="right">
                                Actions
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pendingLicenses.map((license, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        <WildHealthLinkButton
                                            onClick={() => handleReviewLicense(license.id)}
                                            content={license.licenseeName}/>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.businessName}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {moment(new Date(license.submissionDate)).format("MM/DD/YYYY")}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.isFellow ? 'Yes' : 'No'}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="right">
                                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                            handleToggleLicenseActions(!isActionsOpen, license.id, event.currentTarget)}
                                                    disabled={isInProgress}
                                        >
                                            <MoreVertIcon/>
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderActions = () => {
        return (
            <Popover
                keepMounted
                anchorEl={actionsAnchorEl}
                open={isActionsOpen}
                onClose={() => handleToggleLicenseActions(false, 0, null)}
            >
                <WildHealthMenuItem onClick={() => handleReviewLicense(targetLicenseId)}>
                    Review
                </WildHealthMenuItem>
                <WildHealthMenuItem onClick={() => handleApproveLicense()}>
                    Approve
                </WildHealthMenuItem>
                <WildHealthMenuItem onClick={() => handleToggleDenyLicense()}>
                    Deny
                </WildHealthMenuItem>
            </Popover>
        );
    }

    const renderConfirmDenyDialog = () => {
        return (
            <ConfirmDenySubmissionDialogComponent
                isOpened={isConfirmDenyOpened}
                handleClose={() => handleToggleDenyLicense()}
                handleDeny={(reason) => handleDenyLicense(reason)}
            />
        );
    }

    return (
        <Box>
            {renderTable()}
            {renderActions()}
            {renderConfirmDenyDialog()}
        </Box>
    )
}