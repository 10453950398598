import { Box, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { PageTitleComponent } from "../../../../components/pageTitleComponent/PageTitleComponent";
import { Pages } from '../../../../constants/pages.constants';
import { Titles } from "../../../../constants/title.constants";
import { MenuItemType } from "../../../../models/menu.models";
import { ManageBusinessesComponent } from "../../components/manageBusinessesComponent/ManageBusinessesComponent";

export const BusinessesPage: React.FC = () => {
    const pageName = (
        <PageTitleComponent
            title={Pages.businesses}
            endAdornment={
                <IconButton
                    onClick={() => {}}
                    disabled
                >
                    <AddIcon />
                </IconButton>
            }
        />
    )

    return (
        <LayoutComponent
            title={Titles.businesses}
            selectedMenuItem={MenuItemType.Businesses}
            pageName={pageName}
        >
            <Box mt={5} p={5}>
                <ManageBusinessesComponent />
            </Box>
        </LayoutComponent>
    )
}