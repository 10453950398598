import {SnackState} from './snack.models';
import {SnackStore, snackStore} from './snack.store';
import {Query} from '@datorama/akita';

/**
 * Provides snackbar queries
 */
export class SnackQuery extends Query<SnackState> {

    snackState$ = this.select(state => state);

    constructor(protected store: SnackStore) {
        super(store);
    }

    isOpen() {
        return this.getValue().isOpen;
    }

}

export const snackQuery = new SnackQuery(snackStore);
