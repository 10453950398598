import { Pages } from './pages.constants';
export class Titles {
    public static title = 'Wild Health';
    public static subTitle = 'Licensing';
    public static baseTitle = `${Titles.title} ${Titles.subTitle}`;

    public static dashboard = `${Titles.baseTitle} - ${Pages.dashboard}`;
    public static businesses = `${Titles.baseTitle} - ${Pages.businesses}`;

    public static activeLicenses = `${Titles.baseTitle} - ${Pages.activeLicenses}`;
    public static pendingLicenses = `${Titles.baseTitle} - ${Pages.pendingLicenses}`;
    public static inactiveLicenses = `${Titles.baseTitle} - ${Pages.inactiveLicenses}`;
    public static deniedLicenses = `${Titles.baseTitle} - ${Pages.deniedLicenses}`;

    public static license = `${Titles.baseTitle} - ${Pages.license}`;
    public static activeLicense = `${Titles.baseTitle} - ${Pages.activeLicense}`;
    public static approveLicense = `${Titles.baseTitle} - ${Pages.approveLicense}`;

    public static getStarted = `${Titles.baseTitle} - ${Pages.getStarted}`;
    public static getStartedSucceed = `${Titles.baseTitle} - ${Pages.getStartedSucceed}`;
}