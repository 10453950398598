import {useEffect} from "react";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {authService} from "../../services/auth.service";
import {authQuery} from "../../stores/auth";

export function useFacade(accessToken: string): [] {
    const history = useHistory();

    if (authQuery.isLoggedIn()) {
        navigationService.toBusinesses(history);
    }

    useEffect(() => {
        
        const subscriptions = [
            authService.exchangeToken(accessToken).subscribe((token) => {
                authService.authenticate(token).subscribe(
                    () => {
                        navigationService.toBusinesses(history);
                    },
                    () => {
                        navigationService.toDefault(history);
                    })
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [history, accessToken])

    return [];
}
