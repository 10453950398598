import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Box} from '@material-ui/core';
import {useFacade} from "./confirmDialog.hooks";

export const CommonConfirmDialog : React.FC = () => {
    const [state, handleAnswer] = useFacade();

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            handleAnswer(true);
        }
    }

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={state.isOpen}
                onClose={() => handleAnswer(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box mt={2} display="flex" justifyContent="flex-start">
                            <span className="size-1_25 text-medium text-accent">{state.message}</span>
                        </Box>
                    </DialogContentText>
                </DialogContent> 
                <DialogActions>
                    <Button onClick={() => handleAnswer(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={()=> handleAnswer(true)} className="wh-button">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
