import React from 'react';
import {Box, TextField} from "@material-ui/core";
import {BusinessBaseModel} from "../../models/business.model";
import {AddressFormComponent} from "../../../common/components/addressFormComponent/AddressFormComponent";
import {PhoneNumberInputComponent} from "../../../common/components/phoneNumberInputComponent/PhoneNumberInputComponent";

export interface BusinessFormComponentProps {
    model: BusinessBaseModel;
    errors?: { [id: string]: string; }
    handleChanges?: Function;
}

export const BusinessFormComponent: React.FC<BusinessFormComponentProps> = (props: BusinessFormComponentProps) => {
    const {
        model,
        errors,
        handleChanges
    } = props;

    const renderEditForm = () => {
        return (
            <Box>
                <Box width={1} height="75px">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.name}
                        label="Business Name"
                        error={!!errors['name']}
                        helperText={errors['name']}
                        onChange={(v) => handleChanges('name', v.target.value)}
                    />
                </Box>
                <Box>
                    <AddressFormComponent prefix={'address.'} formName='Business ' address={model.address} errors={errors} handleChanges={handleChanges} />
                </Box>
                <Box width={1} height="75px">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.phoneNumber}
                        label="Phone Number"
                        error={!!errors['phoneNumber']}
                        helperText={errors['phoneNumber']}
                        InputProps={{
                            className: 'input',
                            inputComponent: PhoneNumberInputComponent,
                        }}
                        onChange={(v) => handleChanges('phoneNumber', v.target.value)}
                    />
                </Box>
                <Box width={1} height="75px">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.taxIdNumber}
                        label="Tax ID Number"
                        error={!!errors['taxIdNumber']}
                        helperText={errors['taxIdNumber']}
                        onChange={(v) => handleChanges('taxIdNumber', v.target.value)}
                    />
                </Box>
            </Box>
        )
    }

    const renderViewItem =(key: string, value: string) => {
        return (
            <Box mt={2} display="flex">
                <Box width={'150px'}>
                    <span className="text-bold">
                        {key}:
                    </span>
                </Box>
                <Box>{value}</Box>
            </Box>
        )
    }

    const renderView = () => {
        return (
            <Box>
                {renderViewItem('Business Name', model.name)}
                {renderViewItem('Phone Number', model.phoneNumber)}
                {renderViewItem('Tax ID Number', model.taxIdNumber)}
                <Box>
                    <AddressFormComponent prefix={'address.'} formName='Business ' address={model.address} errors={errors} handleChanges={handleChanges} />
                </Box>
            </Box>
        )
    }

    return handleChanges ? renderEditForm() : renderView();
}