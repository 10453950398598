import {useEffect, useState} from "react";
import {onEmit} from "../../../common/helpers/on-emit";
import {LicenseModel, LicenseStatus} from "../../models/licenses.models";
import {licensesQuery} from "../../stores/licenses";
import {licensesService} from "../../services/licenses.service";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";
import { MenuItemType } from "../../../../models/menu.models";

export interface ActiveLicensePageState {
    isLoading: boolean;
    isInProgress: boolean;
    isConfirmCancelOpened: boolean;
    license: LicenseModel;
}

export function useFacade(licenseId: number): [ActiveLicensePageState, () => void, (reason: string) => void] {
    const [state, setState] = useState({
        isLoading: true,
        isInProgress: false,
        isConfirmCancelOpened: false,
        license: null,
    } as ActiveLicensePageState);

    const history = useHistory();

    const handleToggleConfirmCancel = () => {
        setState(state => ({...state, isConfirmCancelOpened: !state.isConfirmCancelOpened}));
    }

    const handleCancelLicense = (reason: string) => {
        setState(state => ({...state, isInProgress: true, isConfirmCancelOpened: false}));

        licensesService.Cancel({id: licenseId, reason: reason}).subscribe(
            () => navigationService.toLicense(history, licenseId, MenuItemType.InactiveLicenses, '/inactive-licenses'),
            () => setState(state => ({...state, isInProgress: false}))
        );
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<LicenseModel>(licensesQuery.targetLicense$, license => {
                if (license && license.id === licenseId) {
                    if (license.status !== LicenseStatus.Approved) {
                        navigationService.toLicense(history, licenseId);
                    }
        
                    setState(state => ({...state, license: license}));
                }
            })
        ];

        licensesService.getById(licenseId).subscribe(
            () => setState(state => ({ ...state, isLoading: false})),
            () => setState(state => ({ ...state, isLoading: false}))
        )

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [licenseId, history])

    return [state, handleToggleConfirmCancel, handleCancelLicense];
}
