import React from 'react';
import {Box, TextField} from '@material-ui/core';
import {AddressModel} from "../../models/address.model";
import {renderEmpty} from "../../helpers/render-empty";
import { addressesKeys, useFacade } from './addressFormComponent.hooks';
import { Autocomplete } from '@material-ui/lab';

interface AddressFormComponentProps {
    address: AddressModel,
    formName?: string,
    prefix?: string;
    errors?: { [id: string]: string; };
    handleChanges?: Function,
    disabled?: boolean
}

export const AddressFormComponent: React.FC<AddressFormComponentProps> = (props: AddressFormComponentProps) => {
    const {
        address,
        prefix,
        errors,
        handleChanges,
        disabled
    } = props;
    let formName = props.formName ?? '';

    const [
        state,
        selectAddress,
        handleAddressChange,
    ] = useFacade(prefix, address.address1, handleChanges);

    const renderInput = (label: string, params: any, error: boolean, onChange: (value: string) => void, helperText?: string) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                error={error}
                label={label}
                onChange={(e) => onChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }

    const renderEditForm = () => {
        return (
            <Box>
                <form autoComplete="off" style={{width: '100%'}}>
                    <Box width={1} height="75px">
                        <Autocomplete
                            size='small'
                            disableClearable
                            disabled={disabled}
                            value={address.address1}
                            placeholder={`Street ${formName}Address 1`}
                            options={state.suggestions.data.map(i => (`${i.structured_formatting?.main_text}, ${i.structured_formatting?.secondary_text}`))}
                            onChange={(e, value) => selectAddress(value)}
                            renderInput={(params) => renderInput(
                                `${formName}Address 1`,
                                params,
                                !!errors[`${prefix}${addressesKeys.streetAddress1}`],
                                handleAddressChange,
                                errors[`${prefix}${addressesKeys.streetAddress1}`]
                            )}
                        />
                    </Box>
                    <Box width={1} height="75px">
                        <TextField
                            fullWidth
                            size='small'
                            value={address.address2}
                            variant="outlined"
                            label={`${formName}Address 2`}
                            placeholder={`Street ${formName}Address 2`}
                            error={!!errors[`${prefix}${addressesKeys.streetAddress2}`]}
                            onChange={(e) => handleChanges(`${prefix}${addressesKeys.streetAddress2}`, e.currentTarget.value)}
                            helperText={errors[`${prefix}${addressesKeys.streetAddress2}`]}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" height="75px">
                        <Box pr={1} width={1}>
                            <TextField
                                fullWidth
                                required
                                disabled={disabled}
                                size="small"
                                placeholder="City"
                                variant="outlined"
                                label="City"
                                InputProps={{className: 'input'}}
                                value={address.city ?? ""}
                                error={!!errors[`${prefix}${addressesKeys.city}`]}
                                helperText={errors[`${prefix}${addressesKeys.city}`]}
                                onChange={(v) => {
                                    handleChanges(`${prefix}${addressesKeys.city}`, v.target.value)
                                }}
                            />
                        </Box>
                        <Box pl={1} width={1}>
                            <TextField
                                fullWidth
                                required
                                disabled={disabled}
                                size="small"
                                placeholder="State"
                                variant="outlined"
                                label="State"
                                InputProps={{className: 'input'}}
                                value={address.state ?? ""}
                                error={!!errors[`${prefix}${addressesKeys.state}`]}
                                helperText={errors[`${prefix}${addressesKeys.state}`]}
                                onChange={(v) => {
                                    handleChanges(`${prefix}${addressesKeys.state}`, v.target.value)
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" height="75px">
                        <Box pr={1} width={1}>
                            <TextField
                                fullWidth
                                required
                                disabled={disabled}
                                size="small"
                                placeholder="Postal/Zip Code"
                                variant="outlined"
                                label="Zip Code"
                                InputProps={{className: 'input'}}
                                value={address.zipCode ?? ""}
                                error={!!errors[`${prefix}${addressesKeys.zipCode}`]}
                                helperText={errors[`${prefix}${addressesKeys.zipCode}`]}
                                onChange={(v) => {
                                    handleChanges(`${prefix}${addressesKeys.zipCode}`, v.target.value)
                                }}
                            />
                        </Box>
                        <Box pl={1} width={1}>
                            <TextField
                                fullWidth
                                required
                                disabled={disabled}
                                size="small"
                                placeholder="Country"
                                variant="outlined"
                                label="Country"
                                InputProps={{className: 'input'}}
                                value={address.country ?? ""}
                                error={!!errors[`${prefix}${addressesKeys.country}`]}
                                helperText={errors[`${prefix}${addressesKeys.country}`]}
                                onChange={(v) => {
                                    handleChanges(`${prefix}${addressesKeys.country}`, v.target.value)
                                }}
                            />
                        </Box>
                    </Box>
                </form>
            </Box>
        )
    }

    const renderViewItem =(key: string, value: string, width: string = '150px') => {
        return (
            <Box mt={2} display="flex">
                <Box width={width}>
                    <span className="text-bold">
                        {key}:
                    </span>
                </Box>
                <Box>{value ?? renderEmpty()}</Box>
            </Box>
        )
    }

    const renderView = () => {
        return (
            <Box>
                <Box mt={2} width={1}>
                    {renderViewItem('Street Address 1', address.address1)}
                </Box>
                <Box mt={2} width={1}>
                    {renderViewItem('Street Address 2', address.address2)}
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box pr={1} width={1}>
                        {renderViewItem('City', address.city, '75px')}
                    </Box>
                    <Box pl={1} width={1}>
                        {renderViewItem('State', address.state, '75px')}
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box pr={1} width={1}>
                        {renderViewItem('Zip Code', address.zipCode, '75px')}
                    </Box>
                    <Box pl={1} width={1}>
                        {renderViewItem('Country', address.country, '75px')}
                    </Box>
                </Box>
            </Box>
        )
    }

    return handleChanges ? renderEditForm() : renderView();
}
