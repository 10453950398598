import Axios from 'axios-observable';
import {Observable} from "rxjs";
import {snackService} from '../../common/snack/state';
import {businessesStore, BusinessesStore} from "../stores/businesses";
import {BusinessModel} from "../models/business.model";
import {authHeader} from "../../common/helpers/auth-header";
import {StartBusinessModel} from "../models/startBusiness.model";

/**
 * Provides method for working with businesses
 */
export class BusinessesService {
    private url = `${process.env.REACT_APP_API_URL}Businesses/`;

    constructor(private store: BusinessesStore) {
    }

    public start(model: StartBusinessModel): Observable<BusinessModel> {
        return new Observable(observer => {
            Axios.post<BusinessModel>(this.url, model, {})
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Your request has been sent!')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        debugger
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all businesses
     */
    public get(): Observable<BusinessModel[]> {
        const config = {headers: authHeader()};
        return new Observable(observer => {
            Axios.get<BusinessModel[]>(this.url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({businesses: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const businessesService = new BusinessesService(businessesStore);
