import React from "react";
import {Box} from "@material-ui/core";
import pciDss from "@img/pci_dss.svg";

export const PaymentFormDescriptionComponent: React.FC = () => {
    return (
        <>
            <Box>
                <img style={{height: '25px'}} src={pciDss} alt="img"/>
            </Box>
            <Box ml={3}>
                <span style={{fontSize: '14px'}}>
                    Our merchant service provider,
                    <a style={{textDecoration: 'none'}} className="text-accent" target="_blank" rel="noopener noreferrer"
                       href="https://stripe.com/about">{' Stripe, '}</a>
                    uses state of the art data encryption
                    <a style={{textDecoration: 'none'}} className="text-accent" target="_blank" rel="noopener noreferrer"
                       href="https://stripe.com/docs/security/stripe">{' security '}</a>
                and is a PCI Level 1 service provider.
                </span>
            </Box>
        </>
    )
}
