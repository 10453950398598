import {createInitialState, SnackStore, snackStore} from './snack.store';
import {SnackState, SnackType} from './snack.models';

/**
 * Populate needed options with default values
 * @param options
 */
const mapOptions = (options: SnackState): SnackState => {
    let result = createInitialState();

    let keys = Object.keys(result);
    for (let k of keys) {
        if (options[k] !== result[k]) {
            result[k] = options[k];
        }
    }

    return result;
}

/**
 * Provides service for showing notifications through snacks
 */
export class SnackService {
    constructor(private snackStore: SnackStore) {
    }

    private static extractMessages(error: any): string[] {
        return error?.response?.data?.messages ?? SnackService.getDefaultErrorMessage();
    }

    private static createErrorMessage(error: any, options: SnackState = null) : SnackState {
        options.messages = SnackService.extractMessages(error);
        options.type = SnackType.error;
        options.duration = 10000;

        return options;
    }

    private static getDefaultErrorMessage(): string[]
    {
        return ['Operation failed.'];
    }

    /**
     * Show snackbar
     * @param options
     */
    show(options: SnackState) {
        let validOptions = mapOptions(options);
        validOptions.isOpen = true;
        this.snackStore.update(validOptions);

        setTimeout(() => this.hide(this.snackStore), validOptions.duration);
    }

    /**
     * Hide snackbar
     */
    hide(store) {
        store.update({isOpen: false} as SnackState);
    }

    /**
     * Show success message
     * @param message
     * @param options
     */
    success(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];

        this.show(options);
    }

    /**
     * Shows error message
     * @param message
     * @param options
     */
    error(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(SnackService.createErrorMessage(message, options));
    }

    /**
     * Shows warning message
     * @param message
     * @param options
     */
    warning(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];
        options.type = SnackType.warning;
        this.show(options);
    }

    /**
     * Show info message
     * @param message
     * @param options
     */
    info(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];
        options.type = SnackType.info;
        this.show(options);
    }

    //#region private

    commonErrorHandler(error: any, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(SnackService.createErrorMessage(error, options));
    }

    //#endregion
}

export const snackService = new SnackService(snackStore);
