import React from 'react';
import {useFacade} from "./loginPage.hooks";

export const LoginPage: React.FC = () => {
    const accessToken = new URLSearchParams(window.location.search).get('access_token')?.replaceAll(' ', '+');

    useFacade(accessToken);

    return (
        <>
            <span>Loading...</span>
        </>
    )
}