import {Store, StoreConfig} from '@datorama/akita';
import {PermissionType, UserType} from '../../models/auth.enums';
import {AuthenticationModel} from "../../models/authentication.model";

export interface AuthState {
    id: number;
    token: string;
    expires: number;
    userType: UserType;
    permissions: PermissionType[];
}

/**
 * Creates initial auth state
 */
export function createInitialState(): AuthState {
    return {
        id: 0,
        token: '',
        expires: 0,
        userType: UserType.Unknown,
        permissions: []
    };
}

/**
 * Provides auth state management
 */
@StoreConfig({name: 'session'})
export class AuthStore extends Store<AuthState> {
    constructor() {
        super(createInitialState());
    }

    public clear(): void {
        this.update(createInitialState());
    }

    public fillOut(model: AuthenticationModel, token: string): void {
        this.update({
            id: model.id,
            userType: model.userType,
            expires: model.expires,
            permissions: model.permissions,
            token: token
        });
    }
}

export const authStore = new AuthStore();
