import {useEffect, useState} from "react";
import {onEmit} from "../../../common/helpers/on-emit";
import {LicenseModel, LicenseStatus} from "../../models/licenses.models";
import {licensesQuery} from "../../stores/licenses";
import {licensesService} from "../../services/licenses.service";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";

export interface LicensePageState {
    isLoading: boolean;
    license: LicenseModel;
}

export function useFacade(licenseId: number): [LicensePageState] {
    const [state, setState] = useState({
        isLoading: true,
        license: null,
    } as LicensePageState);

    const history = useHistory();

    useEffect(() => {
        const subscriptions = [
            onEmit<LicenseModel>(licensesQuery.targetLicense$, license => {
                if (license && license.id === licenseId) {

                    switch (license.status) {
                        case LicenseStatus.Approved: navigationService.toActiveLicense(history, licenseId); break;
                        case LicenseStatus.Pending: navigationService.toApproveLicense(history, licenseId); break;
                        default: break;
                    }
        
                    setState(state => ({...state, license: license}));
                }
            })
        ];

        licensesService.getById(licenseId).subscribe(
            () => setState(state => ({ ...state, isLoading: false})),
            () => setState(state => ({ ...state, isLoading: false}))
        )

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [licenseId, history])

    return [state];
}
