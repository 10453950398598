import { ChangeEvent, useState } from 'react';
import { IErrorState } from '../../../common/validation/error-state';
import { confirmDenySubmissionDialogComponentValidator } from './confirmWithReasonDialogComponent.validator';

export interface ConfirmWithReasonDialogComponentState extends IErrorState {
    reason: string;
}

export function useFacade(handleDeny: (reason: string) => void, onClose: () => void): [
    ConfirmWithReasonDialogComponentState,
    () => void,
    () => void,
    (event: ChangeEvent<HTMLTextAreaElement>) => void
]{
    const [state, setState] = useState({
        reason: '',
        errors: {},
    } as ConfirmWithReasonDialogComponentState);

    const handleCancel = () => {
        setState(state => ({...state, reason: '', errors: {}}));
        onClose();
    }

    const handleReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.currentTarget.value;
        confirmDenySubmissionDialogComponentValidator.validateAndSetState(state, setState, 'reason', value);
        setState(state => ({...state, reason: value}));
    }

    const handleConfirm = () => {
        confirmDenySubmissionDialogComponentValidator.validateAndSetState(state, setState, 'reason', state.reason);

        if (confirmDenySubmissionDialogComponentValidator.stateIsValid(state)) {
            setState(state => ({...state, reason: '', errors: {}}));
            handleDeny(state.reason);
        }
    }

    return [state, handleCancel, handleConfirm, handleReasonChange];
}