import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            borderRadius: 2,
        },
        rootWithBackground: {
            background: '#fff',
        }
    }),
);