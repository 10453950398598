export enum MenuItemType {
    None,
    Dashboard,
    Businesses,
    Licenses,
    ActiveLicenses,
    PendingLicenses,
    InactiveLicenses,
    DeniedLicenses
}

export interface MenuItem {
    title: string;
    type: MenuItemType;
    icon?: (isSelected: boolean) => JSX.Element;
    badge?: JSX.Element;
    children?: MenuItem[];
    action?: (history: any) => void;
}