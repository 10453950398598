import {AddressModel} from "../../common/models/address.model";
import {PracticeModel} from "./practice.model";
import {ProviderModel} from "./provider.model";

export interface BusinessBaseModel {
    name: string;
    address: AddressModel;
    billingAddress: AddressModel;
    phoneNumber: string;
    taxIdNumber: string;
}

export interface RegisterBusinessModel extends BusinessBaseModel {
    paymentToken: string;
}

export const getDefaultRegisterBusinessModel = () => {
    return {
        name: '',
        address: {
            address1: '',
            address2: '',
            country: 'US',
            city: '',
            state: '',
            zipCode: '',
        },
        billingAddress: {
            address1: '',
            address2: '',
            country: 'US',
            city: '',
            state: '',
            zipCode: '',
        },
        phoneNumber: '',
        taxIdNumber: '',
        paymentToken: ''
    };
}

export interface BusinessModel extends BusinessBaseModel {
    id: number;
    licenseeName: string;
    provider: ProviderModel;
    practice: PracticeModel;
    isActive: boolean;
}