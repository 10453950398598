import { BusinessModel } from "../../businesses/models/business.model";
import { PracticeModel } from "../../businesses/models/practice.model";

export enum LicenseStatus {
    Pending = 1,
    Approved = 5,
    Denied = 10,
    Canceled = 15,
    Expired = 20
}

export const licenseStatusNames = new Map<LicenseStatus, string>([
    [LicenseStatus.Pending, 'Pending'],
    [LicenseStatus.Approved, 'Approved'],
    [LicenseStatus.Denied, 'Denied'],
    [LicenseStatus.Canceled, 'Terminated'],
    [LicenseStatus.Expired, 'Contract expired'],
]);

export enum LicenseAccessStatus {
    Denied = 1,
    Allowed = 5,
    ViewOnly = 10
}

export const licenseAccessStatusNames = new Map<LicenseAccessStatus, string>([
    [LicenseAccessStatus.Denied, 'Denied'],
    [LicenseAccessStatus.Allowed, 'Allowed'],
    [LicenseAccessStatus.ViewOnly, 'View Only'],
]);

export interface LicenseModel {
    id: number;
    licenseeName: string;
    status: LicenseStatus;
    statusReason: string;
    statusDate: Date;
    accessStatus: LicenseAccessStatus;
    accessStatusReason: string;
    accessStatusDate: Date;
    createdAt: Date;
    startDate: Date | null;
    endDate: Date | null;
    business: BusinessModel;
    practice: PracticeModel;
}

export interface ApproveLicenseModel {
    id: number;
}

export interface DenyLicenseModel {
    id: number;
    reason: string;
}

export interface CancelLicenseModel {
    id: number;
    reason: string;
}

export interface UpdateAccessStatusModel {
    id: number;
    status: LicenseAccessStatus;
}
