export function descendingComparator<T>(a: T, b: T, orderBy: any) {
    const aValue = a[orderBy] ? a[orderBy].toString().toLowerCase() : "";
    const bValue = b[orderBy] ? b[orderBy].toString().toLowerCase() : "";

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}