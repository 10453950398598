export class Pages {
    public static dashboard = 'Dashboard';
    public static businesses = 'Businesses';

    public static activeLicenses = 'Active Licenses';
    public static pendingLicenses = 'Pending Licenses';
    public static inactiveLicenses = 'Inactive Licenses';
    public static deniedLicenses = 'Denied Licenses';

    public static license = 'License';
    public static activeLicense = 'Active License';
    public static approveLicense = 'Approve License';

    public static getStarted = 'Get Started';
    public static getStartedSucceed = `${Pages.getStarted} Succeed`;
}
