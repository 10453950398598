import React from 'react';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {useFacade} from './state';

export const CommonSnack: React.FC = () => {
    const [{isOpen, type, messages, duration, vertical, horizontal}] = useFacade();

    return (
        <Snackbar open={isOpen}
                  autoHideDuration={duration}
                  anchorOrigin={{vertical: vertical, horizontal: horizontal}}>
            <MuiAlert elevation={6} variant="filled" severity={type}>
                <ul>
                    {
                        messages.map((message, idx) => {
                            return <li key={idx}>{message}</li>
                        })
                    }
                </ul>
            </MuiAlert>
        </Snackbar>
    )
}
