import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {TableCell} from "@material-ui/core";

export const WildHealthTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #EFF3F8',
        },
        head: {
            color: '#afbaca',
            fontWeight: 450,
        },
        body: {
            fontWeight: 450,
        },
    }),
)(TableCell);