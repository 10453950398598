import {Observable, Subscription} from 'rxjs';
import {useEffect, useState} from 'react';
import {SnackState} from './snack.models';
import {createInitialState} from './snack.store';
import {snackQuery} from './snack.query';

function onEmit<T>(source$: Observable<T>, nextFn: (value: T) => void): Subscription {
    return source$.subscribe(nextFn, console.error);
}

/**
 * Custom Hook to manage a view Model for CommonSnack view components
 */
export function useFacade(): [SnackState] {

    const [state, setState] = useState(createInitialState());

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<SnackState>(snackQuery.snackState$, newState => {
                setState(() => (newState));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state];
}
