import {Box, FormControl, IconButton, Popover, Select, TableSortLabel} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";
import React from 'react';
import {useHistory} from "react-router";
import {MenuItemType} from "../../../../models/menu.models";
import {navigationService} from "../../../../services/navigation.service";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import {WildHealthLinkButton} from "../../../common/components/wildHealthLinkButton/WildHealthLinkButton";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import {WildHealthPlaceHolder} from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {LicenseAccessStatus, licenseAccessStatusNames, licenseStatusNames} from "../../models/licenses.models";
import {useFacade} from "./manageInactiveLicensesComponent.hooks";

export const ManageInactiveLicensesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isActionsOpen,
            isInProgress,
            targetLicenseId,
            inactiveLicenses,
            actionsAnchorEl,
            sortingColumns,
            sortingSource,
        },
        handleSorting,
        handleToggleLicenseActions,
        handleOnAccessStatusChanges,
    ] = useFacade();

    const history = useHistory();

    if (isLoading) {
        return <WildHealthLinearProgress/>;
    }

    if (!isLoading && (!inactiveLicenses || !inactiveLicenses.length)) {
        return <WildHealthPlaceHolder message="No current records found"/>
    }

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell width="15%" key={index} align="left">
                                        {
                                            (item.source && item.direction) ?
                                                <TableSortLabel
                                                    active={sortingSource === item.source}
                                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                                    onClick={() => handleSorting(item.source, item.direction)}
                                                >
                                                    {item.title}
                                                </TableSortLabel> : item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell align="right">
                                Actions
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            inactiveLicenses.map((license, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        <WildHealthLinkButton
                                            onClick={() => navigationService.toLicense(history, license.id, MenuItemType.InactiveLicenses)}
                                            content={license.licenseeName}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.businessName}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {`${moment(new Date(license.startDate)).format("MM/DD/YYYY")} - ${moment(new Date(license.endDate)).format("MM/DD/YYYY")}`}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.isFellow ? 'Yes' : 'No'}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {licenseStatusNames.get(license.status)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {license.reason}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <FormControl
                                            component="fieldset"
                                            variant="outlined"
                                            size="small"
                                            fullWidth={true}
                                            disabled={isInProgress}>
                                            <Select
                                                value={license.accessStatus}
                                                onChange={(event) => handleOnAccessStatusChanges(license.id, event.target.value as LicenseAccessStatus)}
                                            >
                                                {
                                                    Array.from(licenseAccessStatusNames).map((item, index) =>
                                                        <WildHealthMenuItem key={index} value={item[0]}>
                                                            {item[1]}
                                                        </WildHealthMenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="right">
                                        <IconButton
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleToggleLicenseActions(!isActionsOpen, license.id, event.currentTarget)}
                                            disabled={isInProgress}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            {renderTable()}
            <Popover
                keepMounted
                anchorEl={actionsAnchorEl}
                open={isActionsOpen}
                onClose={() => handleToggleLicenseActions(false, 0, null)}
            >
                <WildHealthMenuItem
                    onClick={() => navigationService.toLicense(history, targetLicenseId, MenuItemType.InactiveLicenses)}>
                    Review
                </WildHealthMenuItem>
            </Popover>
        </Box>
    )
}