import Axios from 'axios-observable';
import {Observable} from "rxjs";
import {snackService} from '../../common/snack/state';
import {AuthenticationModel} from "../models/authentication.model";
import {authStore, AuthStore} from "../stores/auth";

/**
 * Provides method for working with auth and identity
 */
export class AuthService {
    private url = `${process.env.REACT_APP_API_URL}Authentication/`;

    constructor(private authStore: AuthStore) {
    }

    /**
     * Logouts user
     */
    public logout(): void {
        this.authStore.clear();
    }

    public authenticate(token: string): Observable<AuthenticationModel> {
        const config = {headers: {'Authorization': 'Bearer ' + token}};
        return new Observable(observer => {
            Axios.post<AuthenticationModel>(this.url, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authStore.fillOut(response.data, token);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public exchangeToken(token: string): Observable<string> {
        return new Observable(observer => {
            Axios.post<string>(`${this.url}Token/Exchange`, { token: token })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const authService = new AuthService(authStore);
