import { akitaDevtools, persistState } from '@datorama/akita';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { appInsights, reactPlugin } from '../appInsights';
import { PermissionType, UserType } from "./modules/auth/models/auth.enums";
import { LoginPage } from "./modules/auth/pages/loginPage/LoginPage";
import { NotAuthorizedPage } from "./modules/auth/pages/notAuthorizedPage/NotAuthorizedPage";
import { authQuery } from './modules/auth/stores/auth';
import { BusinessesPage } from "./modules/businesses/pages/businessesPage/BusinessesPage";
import { InactiveLicensesPage } from "./modules/licenses/pages/inactiveLicensesPage/InactiveLicensesPage";
import { PendingLicensesPage } from "./modules/licenses/pages/pendingLicensesPage/PendingLicensesPage";
import { ActiveLicensesPage } from "./modules/licenses/pages/activeLicensesPage/ActiveLicensesPage";
import { DeniedLicensesPage } from "./modules/licenses/pages/deniedLicensesPage/DeniedLicensesPage";
import { ApproveLicensePage } from "./modules/licenses/pages/approveLicensePage/ApproveLicensePage";
import { LicensePage } from "./modules/licenses/pages/licensePage/LicensePage";
import { ActiveLicensePage } from "./modules/licenses/pages/activeLicensePage/ActiveLicensePage";
import { GetStartedPage } from "./modules/businesses/pages/getStartedPage/GetStartedPage";
import { GetStartedSucceedPage } from "./modules/businesses/pages/getStartedSucceedPage/GetStartedSucceedPage";
import { AuthorizedRoute } from "./modules/common/components/AuthorizedRoute";
import { CommonConfirmDialog } from "./modules/common/dialogs/confirmDialog/ConfirmDialog";
import { CommonSnack } from './modules/common/snack/CommonSnack';

akitaDevtools();

persistState({
    include: ['session']
});

const NotFoundRedirect = () => <Redirect to='/businesses'/>

export class App extends React.PureComponent {

    private static CommonRoutes(): JSX.Element[] {
        return ([
            <Route key={'common-1'} path="/login" component={LoginPage} />,
            <Route key={'common-2'} path="/unauthorized" component={NotAuthorizedPage} />,
            <Route key={'common-3'} path="/get-started" component={GetStartedPage} />,
            <Route key={'common-4'} path="/get-started-succeed" component={GetStartedSucceedPage} />,
            <Route key={'common-0'} component={NotFoundRedirect}/>,
        ]);
    }

    private static AuthorizedRoutes(): JSX.Element[] {
        return ([
            <AuthorizedRoute
                key={'authorized-1'}
                path="/businesses"
                component={BusinessesPage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-2'}
                path="/active-licenses"
                component={ActiveLicensesPage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-3'}
                path="/pending-licenses"
                component={PendingLicensesPage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-4'}
                path="/inactive-licenses"
                component={InactiveLicensesPage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-5'}
                path="/denied-licenses"
                component={DeniedLicensesPage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-6'}
                path="/approve-license/:licenseId"
                component={ApproveLicensePage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-7'}
                path="/license/:licenseId"
                component={LicensePage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
            <AuthorizedRoute
                key={'authorized-8'}
                path="/active-license/:licenseId"
                component={ActiveLicensePage}
                userType={UserType.Employee}
                permissions={[PermissionType.ManageLicenses]}
            />,
        ]);
    }

    render() {
        return (
            <Router>
                <>
                    <div className="app">
                        <Switch>
                            {App.AuthorizedRoutes()}
                            {App.CommonRoutes()}
                        </Switch>
                    </div>
                    <CommonSnack/>
                    <CommonConfirmDialog/>
                </>
            </Router>
        );
    }
}

appInsights.setAuthenticatedUserContext(authQuery.getId().toString());

export default withAITracking(reactPlugin, App);
