import React from 'react';
import {Box, Container, Divider, Grid} from "@material-ui/core";
import {BusinessFormComponent} from "../../../businesses/components/businessFormComponent/BusinessFormComponent";
import {PracticeFormComponent} from "../../../businesses/components/practiceFormComponent/PracticeFormComponent";
import {PracticeSettingsFormComponent} from "../../../businesses/components/practiceSettingsFormComponent/PracticeSettingsFormComponent";
import {ProviderFormComponent} from "../../../businesses/components/providerFormComponent/ProviderFormComponent";
import {AddressFormComponent} from "../../../common/components/addressFormComponent/AddressFormComponent";
import {LicenseModel} from "../../models/licenses.models";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";

interface LicenseComponentProps {
    license: LicenseModel;
}

export const LicenseComponent: React.FC<LicenseComponentProps> = (props: LicenseComponentProps) => {
    const {license} = props;

    const renderLicenceForm = () => {
        if (!license.business.provider.medicalLicenses.length) {
            return (
                <Box pt={1}>
                    <WildHealthPlaceHolder height="75px" message="No licenses"/>
                </Box>
            )
        }

        return (
            <ul style={{listStyleType: 'disc'}}>
                {
                    license.business.provider.medicalLicenses.map((license, index) => {
                        return (
                            <Box pl={5} my={2} key={index}>
                                <li>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box width={0.25}>
                                            <Box display="inline" width={'150px'} className="text-bold">State: </Box>
                                            <Box display="inline">{license.state}</Box>
                                        </Box>
                                        <Box width={0.65} px={2}>
                                            <Box display="inline" width={'150px'} className="text-bold">Number: </Box>
                                            <Box display="inline">{license.number}</Box>
                                        </Box>
                                    </Box>
                                </li>
                            </Box>
                        )
                    })
                }
            </ul>
        );
    }

    const renderLicensesForm = () => {
        return (
            <>
                <Box>
                     <Box>
                         <span className="text-bold">
                            Current Licensed States:
                         </span>
                     </Box>
                    <Box>
                        <ul style={{listStyleType: 'disc'}}>
                            {
                                renderLicenceForm()
                            }
                        </ul>
                    </Box>
                </Box>
                <Box pt={3} display="flex">
                    <Box width={'200px'}>
                        <span className="text-bold">
                            National Provider Identifier:
                        </span>
                    </Box>
                    <Box>{license.business.provider.nationalIdentifier}</Box>
                </Box>
                <Box pt={3} display="flex">
                    <Box width={'200px'}>
                        <span className="text-bold">
                            Is Fellowship:
                        </span>
                    </Box>
                    <Box>{license.business.provider.isFellowship ? 'Yes' : 'No'}</Box>
                </Box>
            </>
        )
    }

    const renderProviderInformation = () => {
        return (
            <>
                <Grid item md={5} sm={12} xs={12}>
                    <Box>
                        <Box>
                            <span className="size-1_25 uppercase text-accent">
                                Provider Information
                            </span>
                        </Box>
                        <Box pt={3}>
                            <ProviderFormComponent model={license.business.provider}/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>

                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                    <Box>
                        <Box>
                            <span className="size-1_25 uppercase text-accent">
                                Licenses
                            </span>
                        </Box>
                        <Box pt={3}>
                            {renderLicensesForm()}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box py={5}>
                        <Divider/>
                    </Box>
                </Grid>
            </>
        )
    }

    const renderContent = () => {
        return (
            <Grid container spacing={5}>
                {
                    license.business.provider && renderProviderInformation()
                }
                <Grid item xs={12} sm={12} md={5}>
                    <Box>
                        <Box>
                            <span className="size-1_25 uppercase text-accent">
                                Business Information
                            </span>
                        </Box>
                        <Box pt={3}>
                            <BusinessFormComponent model={license.business}/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>

                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <span className="size-1_25 uppercase text-accent">
                                Billing Address
                            </span>
                        </Box>
                        <Box mt={2}>
                            <AddressFormComponent address={license.business.billingAddress}/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box py={5}>
                        <Divider/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Box>
                        <Box>
                            <span className="size-1_25 uppercase text-accent">
                                Practice Information
                            </span>
                        </Box>
                        <Box pt={3}>
                            <PracticeFormComponent model={license.practice}/>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={2}/>

                <Grid item xs={12} sm={12} md={5}>
                    <PracticeSettingsFormComponent model={license.practice}/>
                </Grid>
            </Grid>
        )
    }

    return (
        <Container maxWidth="lg">
            <Box pt={15} py={5}>
                {
                    renderContent()
                }
            </Box>
        </Container>
    )
}