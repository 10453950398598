import React from 'react';
import {Box, TextField} from "@material-ui/core";
import {ProviderBaseModel} from "../../models/provider.model";
import {PhoneNumberInputComponent} from "../../../common/components/phoneNumberInputComponent/PhoneNumberInputComponent";

export interface ProviderFormComponentProps {
    model: ProviderBaseModel;
    errors?: { [id: string]: string; }
    handleChanges?: Function;
}

export const ProviderFormComponent: React.FC<ProviderFormComponentProps> = (props: ProviderFormComponentProps) => {
    const {
        model,
        errors,
        handleChanges
    } = props;

    const renderEditForm = () => {
        return (
            <Box>
                <Box width={1} display="flex" justifyContent="space-between" height="75px">
                    <Box pr={1} width={1}>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            variant="outlined"
                            value={model.firstName}
                            label="First Name"
                            error={!!errors['firstName']}
                            helperText={errors['firstName']}
                            onChange={(v) => handleChanges('firstName', v.target.value)}
                        />
                    </Box>
                    <Box pl={1} width={1}>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={model.middleName}
                            label="Middle Name"
                            error={!!errors['middleName']}
                            helperText={errors['middleName']}
                            onChange={(v) => handleChanges('middleName', v.target.value)}
                        />
                    </Box>
                </Box>
                <Box width={1} display="flex" justifyContent="space-between" height="75px">
                    <Box pr={1} width={1}>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            variant="outlined"
                            value={model.lastName}
                            label="Last Name"
                            error={!!errors['lastName']}
                            helperText={errors['lastName']}
                            onChange={(v) => handleChanges('lastName', v.target.value)}
                        />
                    </Box>
                    <Box pl={1} width={1}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={model.credentials}
                            label="Credentials"
                            error={!!errors['credentials']}
                            helperText={errors['credentials']}
                            onChange={(v) => handleChanges('credentials', v.target.value)}
                        />
                    </Box>
                </Box>
                <Box width={1} height="75px">
                    <TextField
                        fullWidth
                        required
                        size="small"
                        variant="outlined"
                        label="Phone number"
                        value={model.phoneNumber}
                        error={!!errors['phoneNumber']}
                        helperText={errors['phoneNumber']}
                        InputProps={{
                            className: 'input',
                            inputComponent: PhoneNumberInputComponent,
                        }}
                        onChange={(v) => handleChanges('phoneNumber', v.target.value)}
                    />
                </Box>
            </Box>
        )
    }

    const renderViewItem =(key: string, value: string) => {
        return (
            <Box mt={2} display="flex">
                <Box width={'150px'}>
                    <span className="text-bold">
                        {key}:
                    </span>
                </Box>
                <Box>{value}</Box>
            </Box>
        )
    }

    const renderView = () => {
        return (
            <Box>
                {renderViewItem('First Name', model.firstName)}
                {renderViewItem('Middle Name', model.middleName)}
                {renderViewItem('Last Name', model.lastName)}
                {renderViewItem('Credentials', model.credentials)}
                {renderViewItem('Email', model.email)}
                {renderViewItem('Phone Number', model.phoneNumber)}
            </Box>
        )
    }

    return handleChanges ? renderEditForm() : renderView();
}