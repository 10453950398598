import React from "react";
import { PermissionType } from "../../auth/models/auth.enums";
import { authQuery } from "../../auth/stores/auth";
import { ProtectedRoute } from "./ProtectedRoute";

const validatePermissions = (permissions: PermissionType[], allPermissions: PermissionType[]) => {
    if (permissions.includes(PermissionType.None)) {
        return true;
    }

    return !!permissions.filter(x => allPermissions.includes(x)).length;
}

export const AuthorizedRoute = ({component: Component, userType, permissions, ...rest}) => {
    const isAuthorized = authQuery.isLoggedIn() === true
        && authQuery.getUserType() === userType
        && validatePermissions(permissions, authQuery.getPermissions());

    return (
        <ProtectedRoute
            isAllowed={isAuthorized}
            component={Component}
            {...rest}
        />
    )
}
