import {useState} from 'react';
import {confirmService} from "../../../../services/confirm.service";

export interface ConfirmDialogState {
    isOpen: boolean;
    message: string;
    handleAnswer: Function;
}

export function useFacade(): [ConfirmDialogState, Function]{

    const [state, setState] = useState({
        isOpen: false,
        message: null,
    } as ConfirmDialogState);

    const handleAnswer = (result: boolean) => {
        setState({...state, isOpen: false});
        state.handleAnswer(result);
        setState({...state, isOpen: false, message: null});
    }

    confirmService.onQuestion.subscribe((question) => {
        setState({...state, isOpen: true, message: question.data, handleAnswer: question.callback});
    })

    return [state, handleAnswer];
}