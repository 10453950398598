export class NavigationService {

    public goUp(): void {
        window.scrollTo(0, 0);
    }

    public goDown(): void {
        window.scrollTo(0, document.body.scrollHeight);
    }

    public toDefault(history): void {
        history.push('/');
        this.goUp();
    }

    public to(history, route): void {
        history.push(route);
        this.goUp();
    }

    public toBusinesses(history): void {
        history.push('/businesses');
        this.goUp();
    }

    public toActiveLicenses(history): void {
        history.push('/active-licenses');
        this.goUp();
    }

    public toPendingLicenses(history): void {
        history.push('/pending-licenses');
        this.goUp();
    }

    public toInactiveLicenses(history): void {
        history.push('/inactive-licenses');
        this.goUp();
    }

    public toDeniedLicenses(history): void {
        history.push('/denied-licenses');
        this.goUp();
    }

    public toApproveLicense(history, licenseId: number): void {
        history.push(`/approve-license/${licenseId}`);
        this.goUp();
    }

    public toActiveLicense(history, licenseId: number): void {
        history.push(`/active-license/${licenseId}`);
        this.goUp();
    }

    public toLicense(history, licenseId: number, selectedMenuItem?: number, backPath?: string): void {
        history.push({
            pathname: `/license/${licenseId}`,
            state: {
                selectedMenuItem: selectedMenuItem,
                backPath: backPath,
            }
        });

        this.goUp();
    }

    public toGetStarted(history): void {
        history.push('/get-started');
        this.goUp();
    }

    public toGetStartedSucceed(history, businessModel): void {
        history.push({
            pathname: '/get-started-succeed',
            state: {businessModel: businessModel}
        });

        this.goUp();
    }

    public toClarityPortalApp = () => {
        window.open(`${process.env.REACT_APP_API_URL}Redirect/Portal`);
    }

    public toLoginOnBehalf = (businessId: number, practiceId: number) => {
        window.open(`${process.env.REACT_APP_API_URL}Redirect/Portal/Login/OnBehalf?businessId=${businessId}&practiceId=${practiceId}`);
    }
}

export const navigationService = new NavigationService();