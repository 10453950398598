import { Store, StoreConfig } from '@datorama/akita';
import { AgreementModel } from "../../models/agreements.models";

export interface AgreementsState {
    agreements: AgreementModel[]
}

/**
 * Creates initial agreements state
 */
export function createInitialState(): AgreementsState {
    return {
        agreements: []
    };
}

/**
 * Provides agreements state management
 */
@StoreConfig({name: 'agreements'})
export class AgreementsStore extends Store<AgreementsState> {
    constructor() {
        super(createInitialState());
    }
}

export const agreementsStore = new AgreementsStore();
