import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MenuItem, MenuItemType } from '../../models/menu.models';
import { PermissionType, UserType } from "../../modules/auth/models/auth.enums";
import { mainMenuService } from '../../services/mainMenu.service';
import { applicationQuery, applicationStore } from "../../stores/applicationStore";
import { WildHealthTooltip } from './../../modules/common/components/WildHealthTooltip/WildHealthTooltip';

interface CollapsibleMenuItem extends MenuItem {
    isOpened: boolean;
}

interface SideBarComponentState {
    menus: CollapsibleMenuItem[];
}

export function useFacade(isOpen: boolean, selectedMenuItem: MenuItemType, type: UserType, permissions: PermissionType[], classes: any): [() => void, () => JSX.Element[]] {
    const [state, setState] = useState({
        menus: [],
    } as SideBarComponentState);

    const history = useHistory();

    const handleToggle = () => {
        applicationStore.update({isSideBarOpen: !applicationQuery.getIsSideBarOpen()});
    }

    const handleMenuItemToggle = (item: CollapsibleMenuItem) => {
        item.isOpened = !item.isOpened;
        setState(state => ({...state}));
    }

    const renderChildMenuItems = (item: CollapsibleMenuItem) => {
        return (
            <Collapse in={item.isOpened} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {
                    item.children.map((child, index) => {
                        const isSelected = child.type === selectedMenuItem;

                        return (
                            <ListItem
                                button
                                key={index}
                                hidden={!item.isOpened}
                                className={clsx(classes.menuItem, {
                                    [classes.menuItemSelected]: isSelected,
                                })}
                                onClick={() => child.action(history)}
                            >
                                <ListItemIcon className={classes.menuIcon}>
                                    {child.icon(isSelected)}
                                </ListItemIcon>
                                <ListItemText className={classes.nested} primary={child.title} />
                            </ListItem>
                        )
                    })
                }
                </List>
            </Collapse>
        )
    }

    const renderMenuItems = () => {
        const menuItems: JSX.Element[] = [];

        state.menus.forEach((item, index) => {
            let isSelected = item.type === selectedMenuItem;
            const hasChildren = item.children?.length > 0;
            const action = hasChildren ?
                () => {
                    handleMenuItemToggle(item);
                    if (!isOpen)
                        handleToggle();
                } :
                () => {
                    item.action(history);
                };

            if (hasChildren && !isSelected) {
                isSelected = item.children.find(i => i.type === selectedMenuItem) != null;
            }

            const listItem = (
                <ListItem
                    className={clsx(classes.menuItem, {
                        [classes.menuItemSelected]: isSelected,
                    })}
                    selected={isSelected}
                    onClick={() => action()}
                    key={index}
                    button
                >
                    <ListItemIcon className={classes.menuIcon}>
                        {item.icon(isSelected)}
                        {item.badge}
                    </ListItemIcon>
                    <ListItemText
                        className={classes.menuTitle}
                        primary={item.title}
                        hidden={!isOpen}
                    />
                    {
                        isOpen && hasChildren ? item.isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>
                    }
                </ListItem>
            );

            menuItems.push(isOpen ? listItem : <WildHealthTooltip title={item.title} placement="left">{listItem}</WildHealthTooltip>);

            if (hasChildren)
                menuItems.push(renderChildMenuItems(item));
        })

        return menuItems;
    }

    useEffect(() => {
        const menus = mainMenuService.getMenuItems(type, permissions).map(i => i as CollapsibleMenuItem);
        if (!isOpen)
            menus.forEach(i => i.isOpened = false);

        setState(state => ({...state, menus: menus}));

        return () => {
        };
    }, [isOpen, selectedMenuItem, type, permissions]);

    return [handleToggle, renderMenuItems];
}