import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    emailValidationRule,
    maxLengthValidationRule,
    phoneNumberValidationRule,
    requiredValidationRule,
    linkSegmentValidationRule,
    notWhitespaceValidationRule,
} from "../../../common/validation/general-validation-rules";

export const practiceValidator = new GeneralValidator({
    'name': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'email': [emailValidationRule],
    'phoneNumber': [phoneNumberValidationRule],
    'preferredUrl': [requiredValidationRule(), maxLengthValidationRule(50), notWhitespaceValidationRule(), linkSegmentValidationRule],
    'address.country': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.city': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.state': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.zipCode': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(10)],
    'address.address1': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(100)],
    'address.address2': [notWhitespaceValidationRule(), maxLengthValidationRule(100)],
});