import { Observable, Subject } from "rxjs";
import {CallbackModel} from "../modules/common/dialogs/callback.model"

export class ConfirmService {
    public onQuestion = new Subject<CallbackModel<string>>();

    public confirm(message: string): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestion.next({
                data: message,
                callback: (result: boolean) => {
                    if(result){
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }
}

export const confirmService = new ConfirmService();