import {Box, Button, ButtonGroup, Grid} from "@material-ui/core";
import React from 'react';
import {useHistory} from "react-router";
import {LayoutComponent} from "../../../../components/layoutComponent/LayoutComponent";
import {BackTitleComponent} from "../../../../components/pageTitleComponent/PageTitleComponent";
import {Pages} from "../../../../constants/pages.constants";
import {Titles} from "../../../../constants/title.constants";
import {MenuItemType} from "../../../../models/menu.models";
import {navigationService} from "../../../../services/navigation.service";
import {LicenseComponent} from "../../components/licenseComponent/LicenseComponent";
import {useFacade} from "./activeLicensePage.hooks";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import { ConfirmCancelLicenseDialogComponent } from "../../components/confirmWithReasonDialogComponent/ConfirmWithReasonDialogComponent";

export const ActiveLicensePage: React.FC = (props: any) => {
    const licenseId = Number(props.match.params.licenseId);
    const history = useHistory();
    const [
        {
            isLoading,
            isInProgress,
            isConfirmCancelOpened,
            license,
        },
        handleToggleConfirmCancel,
        handleCancelLicense,
    ] = useFacade(licenseId);

    const pageName = <BackTitleComponent title={Pages.activeLicense} action={() => navigationService.toActiveLicenses(history)}/>;

    const renderContent = () => {
        return (
            <>
                <LicenseComponent license={license}/>

                <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Box pt={5}>
                        <ButtonGroup>
                            <Button
                                disabled={isInProgress}
                                color='secondary'
                                onClick={() => handleToggleConfirmCancel()}
                            >
                                Terminate
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>

                <ConfirmCancelLicenseDialogComponent
                    isOpened={isConfirmCancelOpened}
                    handleClose={() => handleToggleConfirmCancel()}
                    handleCancel={(reason) => handleCancelLicense(reason)}
                />
            </>
        )
    }

    return (
        <LayoutComponent
            title={Titles.activeLicense}
            selectedMenuItem={MenuItemType.ActiveLicenses}
            pageName={pageName}
        >
            <Box mt={5} p={5}>
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : renderContent()
            }
            </Box>
        </LayoutComponent>
    )
}