import React from 'react';

export interface WildHealthLinkButtonProps {
    onClick: Function,
    content: any
}

export const WildHealthLinkButton = (props: WildHealthLinkButtonProps) => {
    return (<span className="pointer-link" onClick={() => props.onClick()}>{props.content}</span>);
}

interface WildHealthExternalLinkButtonProps {
    link: string;
}

export const WildHealthExternalLinkButton: React.FC<WildHealthExternalLinkButtonProps> = (props: WildHealthExternalLinkButtonProps) => {
    const { link } = props;
    const url = link ? link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}` : '';

    return (
        url ?
        <WildHealthLinkButton onClick={() => window.open(url)} content='Link to Purchase' /> :
        <></>
    )
}
