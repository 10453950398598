import React from "react";
import { Redirect, Route } from "react-router";
import { LogOutTimeOutComponent } from "./logOutTimeOutComponent/LogOutTimeOutComponent";

export const ProtectedRoute = ({isAllowed, component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        !isAllowed ? <Redirect to={{
                pathname: '/unauthorized',
                state: {
                    from: window.location.pathname + window.location.search
                }
            }}/> :
        <>
            <Component {...props} />
            <LogOutTimeOutComponent />
        </>
    )}/>
)
