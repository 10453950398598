import React from 'react';

export const renderEmpty = (message?: string) => {
    debugger;
    return (
        <span>
            {
                message ?? '(empty)'
            }
        </span>
    )
}