import { Box, Container, Grid, Paper } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from "react";
import { Titles } from "../../../../constants/title.constants";
import { useFacade } from "./getStartedSucceedPage.hooks";
import { useStyles } from "./getStartedSucceedPage.styles";
import logo from "@img/WH_Clarity_Logo.png";

export const GetStartedSucceedPage: React.FC = (props: any) => {
    const businessModel = props?.location?.state?.businessModel;

    const classes = useStyles();

    const [
        handleGoToBusinesses,
    ] = useFacade();

    if (!businessModel) {
        handleGoToBusinesses();
        return <></>;
    }

    return (
        <Paper className={classes.paper}>
            <title>{Titles.getStartedSucceed}</title>

            <Box p={2}>
                <img style={{height: 75}} src={logo} alt="img"/>
            </Box>

            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} sm={2}/>

                    <Grid item xs={12} sm={8}>
                        <Box my={5} m={2} textAlign="center">
                            <Box height="120px" fontSize="100px">
                                <CheckCircleOutlineIcon fontSize="inherit" className="text-accent"/>
                            </Box>
                            <Box mt={1}>
                                <span className="secondary-font size-2">
                                    Your account has been submitted for approval.
                                </span>
                            </Box>
                            <Box mt={1}>
                                <span className="secondary-font size-2">
                                    Wild Health will contact you shortly.
                                </span>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2}/>
                </Grid>
            </Container>
        </Paper>
    )
}
