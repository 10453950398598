import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    emailValidationRule,
    functionValidationRule,
    maxLengthValidationRule,
    nationalIdentifierValidationRule,
    notWhitespaceValidationRule,
    phoneNumberValidationRule,
    regexpValidationRule,
    requiredValidationRule,
    stringLengthValidationRule
} from "../../../common/validation/general-validation-rules";

export const providerValidator = new GeneralValidator({
    'firstName': [requiredValidationRule(), maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'middleName': [maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'lastName': [requiredValidationRule(), maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'credentials': [requiredValidationRule(), maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'phoneNumber': [phoneNumberValidationRule, notWhitespaceValidationRule()],
    'password': [
        functionValidationRule((p: string) => {return !p.includes(" ")}, "Password should not contain spaces"),
        regexpValidationRule(new RegExp("(?=.*[0-9])"), "Password should contain at least one digit"),
        regexpValidationRule(new RegExp("(?=.*[a-z])"), "Password should contain at least one lower case"),
        regexpValidationRule(new RegExp("(?=.*[A-Z])"), "Password should contain at least one upper case"),
        stringLengthValidationRule(8, null, 'Password should contain at least 8 characters'),
        notWhitespaceValidationRule()
    ],
    'confirmPassword': [stringLengthValidationRule(1, null, 'Confirm password is required.')],

    'state': [requiredValidationRule(1, 'Required'), maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'number': [requiredValidationRule(), maxLengthValidationRule(50), notWhitespaceValidationRule()],
    'nationalIdentifier': [nationalIdentifierValidationRule(false)],
});