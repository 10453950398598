import { Query } from '@datorama/akita';
import { AgreementsState, agreementsStore, AgreementsStore } from "./agreements.store";

/**
 * Provides method for working with agreements store
 */
export class AgreementsQuery extends Query<AgreementsState> {

    agreements$ = this.select(state => state.agreements);

    constructor(protected store: AgreementsStore) {
        super(store);
    }
}

export const agreementsQuery = new AgreementsQuery(agreementsStore);
