import { Box, Button, Dialog, DialogActions, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { AgreementModel } from "../../models/agreements.models";

interface AgreementDialogComponentProps {
    isOpen: boolean;
    agreement: AgreementModel;
    handleClose: () => void,
}

export const AgreementDialogComponent: React.FC<AgreementDialogComponentProps> = (props: AgreementDialogComponentProps) => {
    const
    {
        isOpen,
        agreement,
        handleClose,
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!agreement)
        return <></>;

    return (
        <Dialog
            fullWidth
            open={isOpen}
            maxWidth={'md'}
            fullScreen={isMobile}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent id="agreements-dialog-content">
                <Box mt={5} width={1}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item md={11} sm={12}>
                            <Box py={7} className="agreement">
                                <div id="render-agreement" dangerouslySetInnerHTML={{ __html: agreement.html }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width={140}>
                    <Button
                        onClick={() => handleClose()}
                        className="wh-button"
                        fullWidth
                    >
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}