import {Query} from '@datorama/akita';
import {BusinessesState, businessesStore, BusinessesStore} from "./businesses.store";

/**
 * Provides method for working with businesses store
 */
export class BusinessesQuery extends Query<BusinessesState> {

    businesses$ = this.select(state => state.businesses);

    constructor(protected store: BusinessesStore) {
        super(store);
    }
}

export const businessesQuery = new BusinessesQuery(businessesStore);
