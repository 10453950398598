import React from 'react';
import {Box, TextField} from "@material-ui/core";
import {AddressFormComponent} from "../../../common/components/addressFormComponent/AddressFormComponent";
import {PracticeBaseModel} from "../../models/practice.model";
import {PhoneNumberInputComponent} from "../../../common/components/phoneNumberInputComponent/PhoneNumberInputComponent";

export interface PracticeFormComponentProps {
    model: PracticeBaseModel;
    errors?: { [id: string]: string; }
    disabledAddress?: boolean;
    handleChanges?: Function;
}

export const PracticeFormComponent: React.FC<PracticeFormComponentProps> = (props: PracticeFormComponentProps) => {
    const {
        model,
        errors,
        disabledAddress,
        handleChanges
    } = props;

    const renderEditForm = () => {
        return (
            <Box>
                <Box width={1} height="75px">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.name}
                        label="Practice Name"
                        error={!!errors['name']}
                        helperText={errors['name']}
                        onChange={(v) => handleChanges('name', v.target.value)}
                    />
                </Box>
                <Box>
                    <AddressFormComponent prefix={'address.'} formName='Practice ' address={model.address} errors={errors} handleChanges={handleChanges} disabled={disabledAddress} />
                </Box>
                <Box width={1} height="75px">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.phoneNumber}
                        label="Phone Number"
                        error={!!errors['phoneNumber']}
                        helperText={errors['phoneNumber']}
                        InputProps={{
                            className: 'input',
                            inputComponent: PhoneNumberInputComponent,
                        }}
                        onChange={(v) => handleChanges('phoneNumber', v.target.value)}
                    />
                </Box>
                <Box width={1} height="75px">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={model.email}
                        label="Practice Email"
                        error={!!errors['email']}
                        helperText={errors['email']}
                        onChange={(v) => handleChanges('email', v.target.value)}
                    />
                </Box>
            </Box>
        )
    }

    const renderViewItem =(key: string, value: string) => {
        return (
            <Box mt={2} display="flex">
                <Box width={'150px'}>
                    <span className="text-bold">
                        {key}:
                    </span>
                </Box>
                <Box>{value}</Box>
            </Box>
        )
    }

    const renderView = () => {
        return (
            <Box>
                {renderViewItem('Practice Name', model.name)}
                {renderViewItem('Phone Number', model.phoneNumber)}
                {renderViewItem('Practice Email', model.email)}
                <Box>
                    <AddressFormComponent prefix={'address.'} formName='Practice ' address={model.address} errors={errors} handleChanges={handleChanges} disabled={disabledAddress} />
                </Box>
            </Box>
        )
    }

    return handleChanges ? renderEditForm() : renderView();
}