import React from 'react';
import { ReactComponent as Businesses } from '@img/icons/Businesses.svg';
import { IconProps } from './IconProps';

const BusinessesIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Businesses fill={isSelected ? '#1F72A2' : '#AFBACA'} />
    )
}

export default BusinessesIcon;