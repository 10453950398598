import React from "react";
import {PermissionType, UserType} from "../modules/auth/models/auth.enums";
import {navigationService} from "./navigation.service";
import {MenuItem, MenuItemType} from "../models/menu.models";
import BusinessesIcon from "./../components/iconComponent/BusinessesIcon";
import LicensesIcon from "./../components/iconComponent/LicensesIcon";
import ClarityIcon from "./../components/iconComponent/ClarityIcon";
import SelectedChildIcon from "./../components/iconComponent/SelectedChildIcon";

const pages = {
    businesses: {
        title: 'Businesses',
        type: MenuItemType.Businesses,
        icon: (isSelected: boolean) => <BusinessesIcon isSelected={isSelected} />,
        action: (history) => navigationService.toBusinesses(history)
    },
    licenses : {
        title: 'Licenses',
        type: MenuItemType.Licenses,
        icon: (isSelected: boolean) => <LicensesIcon isSelected={isSelected} />,
        children: [
            {
                title: 'Active Licenses',
                type: MenuItemType.ActiveLicenses,
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                action: (history) => navigationService.toActiveLicenses(history)
            },{
                title: 'Pending Licenses',
                type: MenuItemType.PendingLicenses,
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                action: (history) => navigationService.toPendingLicenses(history)
            },{
                title: 'Inactive Licenses',
                type: MenuItemType.InactiveLicenses,
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                action: (history) => navigationService.toInactiveLicenses(history)
            },{
                title: 'Denied Licenses',
                type: MenuItemType.DeniedLicenses,
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                action: (history) => navigationService.toDeniedLicenses(history)
            },
        ]
    },
    clarity: {
        title: 'Portal',
        type: MenuItemType.None,
        icon: (isSelected: boolean) => <ClarityIcon isSelected={isSelected} />,
        action: (history) => navigationService.toClarityPortalApp()
    },
}

export class MainMenuService {
    private menuItems = new Map<MenuItem, PermissionType[]>([
        [pages.businesses, [
            PermissionType.ManageLicenses
        ]],
        [pages.licenses, [
            PermissionType.ManageLicenses
        ]],
        [pages.clarity, [
            PermissionType.ManageLicenses
        ]],
    ]);

    public getMenuItems(userType: UserType, userPermissions?: PermissionType[]) {
        switch (userType) {
            case UserType.Employee: return this.getMenuItemsByPermissions(userPermissions);
            case UserType.Unknown: return [];
            case UserType.Patient: return [];
            default: return [];
        }
    }

    private getMenuItemsByPermissions(userPermissions?: PermissionType[]) {
        const dashboard: MenuItem[] = [];
        if (!userPermissions || !userPermissions.length) {
            return dashboard;
        }

        const menuItems = Array
            .from(this.menuItems)
            .map(([item, permissions]) => ({item, permissions}));

        const availableItems = menuItems
            .filter(({permissions}) => permissions.some(p=> userPermissions.includes(p)))
            .map(c=> c.item);

        return dashboard.concat(availableItems);
    }
}

export const mainMenuService = new MainMenuService();