import React from 'react';
import { ReactComponent as Licenses } from '@img/icons/Licenses.svg';
import { IconProps } from './IconProps';

const LicensesIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Licenses fill={isSelected ? '#1F72A2' : '#AFBACA'} />
    )
}

export default LicensesIcon;