import {Store, StoreConfig} from '@datorama/akita';
import { LicenseModel } from '../../models/licenses.models';

export interface LicensesStoreState {
    activeLicenses: LicenseModel[];
    pendingLicenses: LicenseModel[];
    inactiveLicenses: LicenseModel[];
    deniedLicenses: LicenseModel[];
    targetLicense: LicenseModel;
}

/**
 * Creates initial licenses state
 */
export function createInitialState(): LicensesStoreState {
    return {
        activeLicenses: [],
        pendingLicenses: [],
        inactiveLicenses: [],
        deniedLicenses: [],
        targetLicense: null,
    };
}

/**
 * Provides pending licenses state management
 */
@StoreConfig({name: 'licenses'})
export class LicensesStore extends Store<LicensesStoreState> {
    constructor() {
        super(createInitialState());
    }
}

export const licensesStore = new LicensesStore();
