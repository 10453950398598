import { useState } from "react";
import { AgreementModel } from "../../models/agreements.models";
import { ConfirmAgreementsCheckBoxComponentProps } from "./ConfirmAgreementsCheckBoxComponent";

interface ConfirmAgreementsCheckBoxComponentState {
    isAgreementOpen: boolean;
    selectedAgreement: AgreementModel;
}

export function useFacade(props: ConfirmAgreementsCheckBoxComponentProps): [
    ConfirmAgreementsCheckBoxComponentState,
    string,
    (agreement: AgreementModel, show: boolean) => void,
    (agreement: AgreementModel) => boolean,
    (agreement: AgreementModel) => string,
    () => string,
]{
    const {
        agreements,
        agreementsConfirmations,
        errors
    } = props;

    const errorColor = "#f44336";
    const primaryColor = "#537b98";

    const [state, setState] = useState({
        isAgreementOpen: false,
        selectedAgreement: null,
    } as ConfirmAgreementsCheckBoxComponentState);

    const handleAgreementDialogToggle = (agreement: AgreementModel, show: boolean) => {
        setState(state => ({...state, isAgreementOpen: show, selectedAgreement: agreement}));
    }

    const isChecked = (agreement: AgreementModel) => {
        return agreementsConfirmations.find(x => x.agreementId === agreement.id)?.isConfirmed;
    }

    const getCheckBoxColor = (agreement: AgreementModel) => {
        return !errors[agreement.name]
            ? primaryColor
            : errorColor;
    }

    const getErrorMessage = () => {
        const failedAgreement = agreements.find(x => errors[x.name]);
        return failedAgreement
            ? errors[failedAgreement.name]
            : null;
    }

    return [state, errorColor, handleAgreementDialogToggle, isChecked, getCheckBoxColor, getErrorMessage];
}