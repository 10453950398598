import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from '../../common/snack/state';
import { ApproveLicenseModel, CancelLicenseModel, DenyLicenseModel, LicenseModel, UpdateAccessStatusModel } from '../models/licenses.models';
import { licensesStore, LicensesStore } from "../stores/licenses";

/**
 * Provides method for working with pending licenses
 */
export class LicensesService {
    private url = `${process.env.REACT_APP_API_URL}Licenses`;

    constructor(private store: LicensesStore) {
    }

    public getActive(): Observable<LicenseModel[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<LicenseModel[]>(`${this.url}/Active`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({activeLicenses: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getPending(): Observable<LicenseModel[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<LicenseModel[]>(`${this.url}/Pending`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({pendingLicenses: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getInactive(): Observable<LicenseModel[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<LicenseModel[]>(`${this.url}/Inactive`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({inactiveLicenses: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getDenied(): Observable<LicenseModel[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<LicenseModel[]>(`${this.url}/Denied`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({deniedLicenses: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getById(licenseId: number): Observable<LicenseModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<LicenseModel>(`${this.url}/${licenseId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetLicense: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public Approve(model: ApproveLicenseModel): Observable<LicenseModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<LicenseModel>(`${this.url}/Approve`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetLicense: response.data})
                        snackService.success('License successfully approved.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public Deny(model: DenyLicenseModel): Observable<LicenseModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<LicenseModel>(`${this.url}/Deny`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetLicense: response.data})
                        snackService.success('License successfully denied.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public Cancel(model: CancelLicenseModel): Observable<LicenseModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<LicenseModel>(`${this.url}/Cancel`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetLicense: response.data});
                        snackService.success('License successfully canceled.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public UpdateAccessStatus(model: UpdateAccessStatusModel): Observable<LicenseModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<LicenseModel>(`${this.url}/AccessStatus`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetLicense: response.data});
                        snackService.success('Status successfully updated.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const licensesService = new LicensesService(licensesStore);
