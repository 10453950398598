import { useEffect } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { timer } from 'rxjs';
import { navigationService } from "../../../../services/navigation.service";
import { authService } from "../../../auth/services/auth.service";
import { authQuery } from "../../../auth/stores/auth";

export function useFacade(): void {
    const startAfter = 10000;
    const period = 10000;
    const sourceTimer = timer(startAfter, period);
    const history = useHistory();

    const timerSubscription = () => {
        if (authQuery.isLoggedIn() === false) {
            authService.logout();
            navigationService.toDefault(history);
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [ sourceTimer.subscribe(timerSubscription) ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);
}
