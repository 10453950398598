import { Query } from '@datorama/akita';
import { LicensesStoreState, licensesStore, LicensesStore } from "./licensesStore";

/**
 * Provides method for working with pending licenses store
 */
export class LicensesQuery extends Query<LicensesStoreState> {
    activeLicenses$ = this.select(state => state.activeLicenses);
    pendingLicenses$ = this.select(state => state.pendingLicenses);
    inactiveLicenses$ = this.select(state => state.inactiveLicenses);
    deniedLicenses$ = this.select(state => state.deniedLicenses);
    targetLicense$ = this.select(state => state.targetLicense);

    constructor(protected store: LicensesStore) {
        super(store);
    }
}

export const licensesQuery = new LicensesQuery(licensesStore);
