import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import React from 'react';
import { useHistory } from "react-router";
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { BackTitleComponent } from "../../../../components/pageTitleComponent/PageTitleComponent";
import { Pages } from "../../../../constants/pages.constants";
import { Titles } from "../../../../constants/title.constants";
import { MenuItemType } from "../../../../models/menu.models";
import { navigationService } from "../../../../services/navigation.service";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import { ConfirmDenySubmissionDialogComponent } from "../../components/confirmWithReasonDialogComponent/ConfirmWithReasonDialogComponent";
import { LicenseComponent } from "../../components/licenseComponent/LicenseComponent";
import { useFacade } from "./approveLicensePage.hooks";

export const ApproveLicensePage: React.FC = (props: any) => {
    const licenseId = Number(props.match.params.licenseId);
    const history = useHistory();

    const [
        {
            license,
            isLoading,
            isSubmitted,
            isConfirmDenyOpened,
        },
        handleToggleConfirmDeny,
        handleDeny,
        handleApprove,
    ] = useFacade(licenseId);

    const pageName = <BackTitleComponent title={Pages.approveLicense} action={() => navigationService.toPendingLicenses(history)}/>;

    const renderContent = () => {
        return (
            <>
                <LicenseComponent license={license}/>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Box pt={5}>
                        <ButtonGroup>
                            <Button
                                disabled={isSubmitted}
                                color='secondary'
                                onClick={() => handleToggleConfirmDeny()}
                            >
                                Deny
                            </Button>
                            <Button
                                disabled={isSubmitted}
                                color='primary'
                                onClick={() => handleApprove()}
                            >
                                Approve
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Grid>

                <ConfirmDenySubmissionDialogComponent
                    isOpened={isConfirmDenyOpened}
                    handleClose={() => handleToggleConfirmDeny()}
                    handleDeny={(reason) => handleDeny(reason)}
                />
            </>
        )
    }

    return (
        <LayoutComponent
            title={Titles.approveLicense}
            selectedMenuItem={MenuItemType.PendingLicenses}
            pageName={pageName}
        >
            <Box mt={5} p={5}>
            {
                isLoading
                    ? <WildHealthLinearProgress/>
                    : renderContent()
            }
            </Box>
        </LayoutComponent>
    )
}