import * as dotenv from 'dotenv';

dotenv.config();
let path;

switch (process.env.NODE_ENV) {
    case 'test':
        path = `${__dirname}../../../../config/.env.test`;
        break;
    case 'production':
        path = `${__dirname}../../../../config/.env.production`;
        break;
    default:
        path = `${__dirname}../../../../config/.env.development`;
}
dotenv.config({path: path});

export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const APPINSIGHTS_INSTRUMENTATIONKEY = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
export const PAYMENT_KEY = process.env.REACT_APP_PAYMENT_KEY;
