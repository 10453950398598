/**
 * Provides general validator
 */
import {ValidationRule} from "./validation-rule";
import {IErrorState} from "./error-state";

export class GeneralValidator {
    private readonly _map: { [field: string]: ValidationRule[] } = {};

    constructor(maps: { [field: string]: ValidationRule[] }) {
        this._map = maps;
    }

    public validate(field: string, value: string): string {
        const rules = this._map[field];
        if (!rules) {
            return null;
        }

        let error = null;
        rules.forEach(rule => {
            const isValid = rule.function(value ?? '');
            if (!isValid) {
                error = rule.message;
            }
        });

        return error;
    }

    public validateAndSetState(state: IErrorState, setState: Function, field: string, value: string | any): void {
        const error = this.validate(field, value);
        if (error) {
            setState({...state, errors: GeneralValidator.addError(state, field, error)});
        } else {
            setState({...state, errors: GeneralValidator.removeError(state, field)});
        }
    }

    public validateObjectAndSetState(state: IErrorState, setState: Function, object: Object): void {
        Object.keys(object).forEach(field => {
            this.validateAndSetState(state, setState, field, object[field]);
        });
    }

    public stateIsValid(state: IErrorState): boolean {
        return Object.keys(state.errors).length === 0
    }

    public static addError(state: IErrorState, field: string, error: string): { [id: string]: string; } {
        state.errors[field] = error;
        return {...state.errors};
    }

    public static removeError(state: IErrorState, field: string): { [id: string]: string; } {
        if (state.errors[field]) {
            delete state.errors[field];
        }
        return {...state.errors};
    }

    public static isValidState(state: IErrorState): boolean {
        return Object.keys(state.errors).length === 0
    }
}
