import {Store, StoreConfig} from '@datorama/akita';
import {BusinessModel} from "../../models/business.model";

export interface BusinessesState {
    businesses: BusinessModel[]
}

/**
 * Creates initial businesses state
 */
export function createInitialState(): BusinessesState {
    return {
        businesses: []
    };
}

/**
 * Provides businesses state management
 */
@StoreConfig({name: 'businesses'})
export class BusinessesStore extends Store<BusinessesState> {
    constructor() {
        super(createInitialState());
    }
}

export const businessesStore = new BusinessesStore();
