import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core';
import React from 'react';
import { useFacade } from "./confirmWithReasonDialogComponent.hooks";

interface ConfirmWithReasonDialogComponentProps {
    isOpened: boolean;
    message: string;
    handleClose: () => void;
    handleConfirm: (reason: string) => void;
}

export const ConfirmWithReasonDialogComponent : React.FC<ConfirmWithReasonDialogComponentProps> = (props: ConfirmWithReasonDialogComponentProps) => {
    const {
        isOpened,
        message,
        handleClose: onClose,
        handleConfirm: onConfirm,
    } = props;

    const [
        {
            reason,
            errors,
        },
        handleCancel,
        handleConfirm,
        handleReasonChange,
    ] = useFacade(onConfirm, onClose);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleConfirm();
        }
    }

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={isOpened}
                onClose={() => handleCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box mt={2} display="flex" justifyContent="flex-start">
                            <span className="size-1_25 text-medium text-accent">{message}</span>
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                required
                                rows={3}
                                multiline
                                type='text'
                                size="small"
                                variant="outlined"
                                label='Reason'
                                placeholder='Reason'
                                value={reason}
                                InputProps={{className: 'input'}}
                                onChange={handleReasonChange}
                                error={!!errors['reason']}
                                helperText={errors['reason']}
                            />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box>
                        <Button onClick={() => handleCancel()} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box mx={2}>
                        <Button onClick={() => handleConfirm()} className="wh-button">
                            Submit
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

interface ConfirmDenySubmissionDialogComponentProps {
    isOpened: boolean;
    handleClose: () => void;
    handleDeny: (reason: string) => void;
}

export const ConfirmDenySubmissionDialogComponent : React.FC<ConfirmDenySubmissionDialogComponentProps> = (props: ConfirmDenySubmissionDialogComponentProps) => {
    return (
        <ConfirmWithReasonDialogComponent
            isOpened={props.isOpened}
            message='Are you sure you want to deny this application?'
            handleClose={() => props.handleClose()}
            handleConfirm={(reason) => props.handleDeny(reason)}
        />
    )
}

interface ConfirmCancelLicenseDialogComponentProps {
    isOpened: boolean;
    handleClose: () => void;
    handleCancel: (reason: string) => void;
}

export const ConfirmCancelLicenseDialogComponent : React.FC<ConfirmCancelLicenseDialogComponentProps> = (props: ConfirmCancelLicenseDialogComponentProps) => {
    return (
        <ConfirmWithReasonDialogComponent
            isOpened={props.isOpened}
            message='Are you sure you want to terminate this license?'
            handleClose={() => props.handleClose()}
            handleConfirm={(reason) => props.handleCancel(reason)}
        />
    )
}
