import { Box, IconButton, Popover, TableSortLabel } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import WildHealthLinearProgress from "../../../common/components/wildHealthLinearProgress/WildHealthLinearProgress";
import { WildHealthLinkButton } from "../../../common/components/wildHealthLinkButton/WildHealthLinkButton";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { ConfirmCancelLicenseDialogComponent } from "../confirmWithReasonDialogComponent/ConfirmWithReasonDialogComponent";
import { useFacade } from "./manageActiveLicensesComponent.hooks";

export const ManageActiveLicensesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isActionsOpen,
            isInProgress,
            isConfirmCancelOpened,
            targetLicenseId,
            activeLicenses,
            actionsAnchorEl,
            sortingColumns,
            sortingSource,
        },
        handleSorting,
        handleToggleLicenseActions,
        handleEditLicense,
        handleResendLicenseInvite,
        handleToggleConfirmCancel,
        handleCancelLicense,
    ] = useFacade();

    const history = useHistory();

    if (isLoading) {
        return <WildHealthLinearProgress/>;
    }

    if (!isLoading && (!activeLicenses || !activeLicenses.length)) {
        return <WildHealthPlaceHolder message="No current records found"/>
    }

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell width="25%" key={index} align="left">
                                        {
                                            (item.source && item.direction) ?
                                                <TableSortLabel
                                                    active={sortingSource === item.source}
                                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                                    onClick={() => handleSorting(item.source, item.direction)}
                                                >
                                                    {item.title}
                                                </TableSortLabel> :
                                                item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell align="right">
                                Actions
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                    {
                        activeLicenses.map((license, index) =>
                            <WildHealthTableRow key={index}>
                                <WildHealthTableCell align="left">
                                    <WildHealthLinkButton
                                        onClick={() => navigationService.toActiveLicense(history, license.id)}
                                        content={license.licenseeName}/>
                                </WildHealthTableCell>
                                <WildHealthTableCell align="left">
                                    {license.businessName}
                                </WildHealthTableCell>
                                <WildHealthTableCell align="left">
                                    {license.businessAddress}
                                </WildHealthTableCell>
                                <WildHealthTableCell align="left">
                                    {license.activePatients}
                                </WildHealthTableCell>
                                <WildHealthTableCell align="right">
                                    <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                        handleToggleLicenseActions(!isActionsOpen, license.id, event.currentTarget)}
                                        disabled={isInProgress}
                                    >
                                        <MoreVertIcon/>
                                    </IconButton>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderActions = () => {
        return (
            <Popover
                keepMounted
                anchorEl={actionsAnchorEl}
                open={isActionsOpen}
                onClose={() => handleToggleLicenseActions(false, 0, null)}
            >
                <WildHealthMenuItem onClick={() => navigationService.toActiveLicense(history, targetLicenseId)}>
                    Review
                </WildHealthMenuItem>
                <WildHealthMenuItem onClick={() => handleEditLicense()} disabled>
                    Edit
                </WildHealthMenuItem>
                <WildHealthMenuItem onClick={() => handleResendLicenseInvite()} disabled>
                    Resend Invite
                </WildHealthMenuItem>
                <WildHealthMenuItem onClick={() => handleToggleConfirmCancel()}>
                    Terminate
                </WildHealthMenuItem>
            </Popover>
        );
    }

    const renderConfirmCancelDialog = () => {
        return (
            <ConfirmCancelLicenseDialogComponent
                isOpened={isConfirmCancelOpened}
                handleClose={() => handleToggleConfirmCancel()}
                handleCancel={(reason) => handleCancelLicense(reason)}
            />
        );
    }

    return (
        <Box>
            {renderTable()}
            {renderActions()}
            {renderConfirmCancelDialog()}
        </Box>
    )
}