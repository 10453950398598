import {
    ApplicationState,
    applicationStore,
    ApplicationStore
} from './application.store';
import { Query } from '@datorama/akita';

/**
 * Provides method for working with application store
 */
export class ApplicationQuery extends Query<ApplicationState> {
    isSideBarOpen$ = this.select(state => state.isSideBarOpen);

    constructor(protected store: ApplicationStore) {
        super(store);
    }

    public getIsSideBarOpen(): boolean {
        return this.getValue().isSideBarOpen;
    }
}

export const applicationQuery = new ApplicationQuery(applicationStore);
