import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { LicenseModel } from "../../models/licenses.models";
import { licensesService } from "../../services/licenses.service";
import { licensesQuery } from "../../stores/licenses";

export enum SortingSource {
    Name = "licenseeName",
    BusinessName = "businessName",
    BusinessAddress = "businessAddress",
    ActivePatients = "activePatients",
}

const columns = [
    {
        title: 'Name',
        source: SortingSource.Name,
        direction: SortingDirection.Asc
    },
    {
        title: 'Business Name',
        source: SortingSource.BusinessName,
        direction: SortingDirection.Asc
    },
    {
        title: 'Business Address',
        source: SortingSource.BusinessAddress,
        direction: SortingDirection.Asc
    },
    {
        title: 'Active Patients',
        source: SortingSource.ActivePatients,
        direction: SortingDirection.Asc
    }
];

export interface LicenseViewModel {
    id: number;
    licenseeName: string;
    businessName: string;
    businessAddress: string;
    activePatients: string;
}

export interface ManageActiveLicensesComponentState extends ISortingState {
    isLoading: boolean;
    isActionsOpen: boolean;
    isInProgress: boolean;
    isConfirmCancelOpened: boolean;
    targetLicenseId: number;
    activeLicenses: LicenseViewModel[];
    actionsAnchorEl: HTMLButtonElement;
}

export function useFacade(): [
    ManageActiveLicensesComponentState,
    (source: string, direction: SortingDirection) => void,
    (value: boolean, licenseId: number, target: HTMLButtonElement) => void,
    () => void,
    () => void,
    () => void,
    (reason: string) => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        isActionsOpen: false,
        isInProgress: false,
        isConfirmCancelOpened: false,
        targetLicenseId: 0,
        activeLicenses: [],
        actionsAnchorEl: null,
        sortingColumns: columns,
        sortingSource: SortingSource.Name
    } as ManageActiveLicensesComponentState);

    const mapLicenses = (licenses: LicenseModel[]) => {
        return licenses.map(i => {
            return {
                id: i.id,
                licenseeName: i.licenseeName,
                businessName: i.business?.name,
                businessAddress: `${i.business.address.address1} ${i.business.address.city}, ${i.business.address.state}, ${i.business.address.zipCode}`,
                activePatients: 'N/A',
            };
        });
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({
            ...state,
            columns: state.sortingColumns
        }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            businesses: state.activeLicenses.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleToggleLicenseActions = (value: boolean, licenseId: number, target: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            isActionsOpen: value,
            targetLicenseId: licenseId,
            actionsAnchorEl: target
        }))
    }

    const handleEditLicense = () => {

    }

    const handleResendLicenseInvite = () => {

    }

    const handleToggleConfirmCancel = () => {
        setState(state => ({...state, isConfirmCancelOpened: !state.isConfirmCancelOpened, isActionsOpen: false}));
    }

    const handleCancelLicense = (reason: string) => {
        setState(state => ({...state, isConfirmCancelOpened: false}));

        licensesService.Cancel({id: state.targetLicenseId, reason: reason}).subscribe(() =>
            setState(state => ({...state, activeLicenses: state.activeLicenses.filter(i => i.id !== state.targetLicenseId)}))
        );
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<LicenseModel[]>(licensesQuery.activeLicenses$, licenses => {
                setState(state => ({...state, activeLicenses: mapLicenses(licenses)}));
            }),
        ];

        licensesService.getActive().subscribe(
            () => setState(state => ({...state, isLoading: false})),
            () => setState(state => ({...state, isLoading: false}))
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [state, handleSorting, handleToggleLicenseActions, handleEditLicense, handleResendLicenseInvite, handleToggleConfirmCancel, handleCancelLicense];
}
