import { Box } from "@material-ui/core";
import { useStyles } from "./wildHealthContainer.styles";
import React from "react";
import clsx from 'clsx';

interface WildHealthContainerProps {
    hideBackground?: boolean;
    minHeight?: any;
    margin?: number;
    children: React.ReactNode;
}

export const WildHealthContainer: React.FC<WildHealthContainerProps> = (props: WildHealthContainerProps) => {
    const { children, margin, minHeight, hideBackground, } = props;
    const classes = useStyles();

    return (
        <Box
            m={margin ?? '30px'}
            minHeight={minHeight ?? 'calc(100vh - 140px)'}
            className={clsx(classes.root, {[classes.rootWithBackground]: !hideBackground})}
        >
            {children}
        </Box>
    );
};