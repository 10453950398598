import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../modules/common/helpers/on-emit";
import { applicationQuery } from "../../stores/applicationStore";

interface LayoutComponentState {
    isSideBarOpen: boolean;
}

export function useFacade(): [LayoutComponentState] {

    const [state, setState] = useState({
        isSideBarOpen: applicationQuery.getIsSideBarOpen(),
    } as LayoutComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(applicationQuery.isSideBarOpen$, isSideBarOpen => {
                setState(state => ({...state, isSideBarOpen: isSideBarOpen}));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state];
}

